@import "../../styles/branding.scss";
@import "../../styles/style.scss";

#productinfocontainer {
  width: $page-max-width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  #productinfoleft {
    width: 100%;
    text-align: center;
  }
}
@media screen and (min-width: 1025px) {
  #productinfocontainer {
    width: $page-max-width;
    display: flex;
    flex-direction: row;
    #productinfoleft {
      width: 50%;
    }
    #productinforight {
      width: 50%;
    }
  }
}
@media screen and (max-width: $screen__m) {
  #productinfocontainer {
    width: 100%;
  }
}
@media screen and (max-width: $screen__xs) {
  #productinfoleft {
    .mediaGallery {
      .carousel-thumbnails {
        margin-top: 3rem;
        margin-bottom: 3rem;
      }
    }
  }
}

@import "../../../../styles/branding.scss";
#searchinput {
  width: 100%;
  position: absolute;
  top: -8rem;
  border: none;
  height: 5rem;
  top: 0;
  .search-inner {
    text-align: center;
    background: $primary;
    padding: 2rem 0;
    box-shadow: 0 0.3rem 0.6rem #00000029;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
  }
  .search-icon {
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    padding: 0 2rem;
    &:after {
      content: "";
      background: url("../../../../assets/searchWhite.svg");
      background-repeat: no-repeat;
      width: 2rem;
      height: 2rem;
      display: inline-block;
    }
  }
  input {
    position: relative;
    font-style: italic;
    letter-spacing: 0.12rem;
    width: auto;
    border: none;
    background: $primary;
    color: $white;
    &:focus {
      border: none;
      outline: none;
      outline-color: none;
    }
  }

  ::placeholder {
    color: $white;
    opacity: 1;
    text-transform: uppercase;
  }
}
#searchinput.slide-exit-done {
  #search-products {
    display: none;
  }
}

@import "../../styles/branding.scss";
@import "../../styles/style.scss";

.breadcrumbs {
  ul {
    padding-left: 0;
    margin-top: 1.5rem;
    li {
      a {
        text-decoration: none;
        font-size: $f16;
        text-transform: uppercase;
        color: $secondary;
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .breadcrumbs {
    ul {
      li {
        a {
          font-size: $f12;
        }
      }
    }
  }
}

@import "../../../styles/branding";
@import "../../../styles/style";
.contact-us-container {
  .breadcrumbs {
    @extend %block-width-1350;
    padding-bottom: 4rem;
  }
  #cmsblock-contact_us_info {
    overflow: hidden;
  }
}
.contact-us-form {
  h1 {
    margin-bottom: 5rem;
  }
  .input.contact-msg {
    textarea.input-contact-msg {
      width: 100%;
    }
  }
}
.block-info-container {
  background: $gray-5;
  position: relative;
  -webkit-box-shadow: inset 0 2rem 2rem -2rem rgba(0, 0, 0, 0.8);
  -moz-box-shadow: inset 0 2rem 2rem -2rem rgba(0, 0, 0, 0.8);
  box-shadow: inset 0 2rem 2rem -2rem rgba(0, 0, 0, 0.8);
  .block-info-wrapper {
    .block-info-inner {
      padding: 10rem;
      align-content: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .block-info {
        width: calc(50% - 1.5rem);
        &.image {
          img {
            max-width: 100%;
          }
        }
        p {
          font-size: $f21;
        }
        &.content {
          flex: none !important;
          a {
            height: 6rem;
            font-size: 1.4rem;
            display: inline-block;
            position: relative;
            border-bottom: 0.2rem solid $primary;
            margin: 2rem 0;
          }
          ul {
            padding: 0;
            margin: 0;
            li {
              position: relative;
              display: flex;
              align-items: center;
              padding-left: 4rem;
              margin: 0;
              a {
                height: auto;
                margin: 0.5rem 0 1rem 0;
                border-bottom: 0;
                text-decoration: none;
                transition: all 0.2s ease-in-out;
                font-size: $f14;
                &:hover {
                  color: #000;
                }
              }
              &:before {
                content: "";
                display: block;
                width: 3rem;
                height: 2rem;
                background-repeat: no-repeat;
                position: absolute;
                left: 0;
              }
              &.mail {
                margin: 1rem 0;
                &:before {
                  background-image: url("../../../assets/email-orange.svg");
                }
              }
              &.phone-one {
                margin: 1rem 0;
                &:before {
                  background-image: url("../../../assets/fax.svg");
                }
              }
              &.phone-two {
                margin: 1rem 0;
                &:before {
                  background-image: url("../../../assets/phone-orange.svg");
                }
              }
            }
          }
        }
      }
    }
  }
}
.block-map-container {
  .map-image {
    img {
      display: inherit;
      width: 100%;
    }
  }
}
@media screen and (max-width: $screen__l) {
  .contact-us-container {
    .breadcrumbs {
      padding-bottom: 0;
    }
  }

  .contact-us-form {
    padding: 3.7rem 3rem;
  }
  .block-info-container {
    .block-info-wrapper {
      .block-info-inner {
        padding: 3.7rem 3rem;
      }
    }
  }
}
@media screen and (min-width: 1441px) {
  .contact-us-form {
    width: $page-max-width;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1025px) {
  .block-info-container {
    .block-info-wrapper {
      width: $page-max-width;
      margin: 0 auto;
    }
  }
}
@media screen and (max-width: 1025px) {
  .block-info-container {
    .block-info-wrapper {
      .block-info-inner {
        .block-info {
          &.image {
            text-align: center;
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__m) {
  .block-info-container {
    .block-info-wrapper {
      .block-info-inner {
        .block-info {
          &.content {
            h2 {
              line-height: normal;
              padding-bottom: 0;
            }
            a {
              margin: 1rem 0;
              height: 4rem;
            }
            ul {
              li.phone-two,
              li.phone-one,
              li.mail {
                margin: 0.5rem 0;
                a {
                  margin: 0.5rem 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .block-info-container {
    .block-info-wrapper {
      .block-info-inner {
        .block-info {
          width: 100%;
          p {
            font-size: $f16;
          }
          .contact-info {
            ul {
              li {
                a {
                  font-size: $f16;
                }
              }
            }
          }
        }
        .block-info.image {
          margin-bottom: 3rem;
        }
      }
    }
  }
}

@import "../../../styles/branding";
@import "../../../styles/style";

.login-page {
  padding-bottom: 4rem;
  .legend {
    h1 {
      position: relative;
      margin-bottom: 3rem;
      &:after {
        @extend %title-bottom-border;
      }
    }
    h3 {
      &:after {
        display: none;
      }
    }
  }
  .logged-in-message {
    font-size: $f16;
    color: $secondary;
  }
  #login-form {
    .forgot-pw {
      font-size: $f14;
      text-align: left;
    }
  }
}

@import "../../../../../styles/branding.scss";
@import "../../../../../styles/style.scss";
.home-category-item {
  text-decoration: none;
  .home-category-item-inner {
    .home-category-item-img {
      img {
        width: 100%;
      }
    }
    h2 {
      @extend %nunito-sans-semi-bold;
    }
  }
}

@import "../../../styles/branding";
@import "../../../styles/style";
.contact-us-form-container {
  form {
    margin: 0;
    .form-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      border-bottom: 0.1 solid $primary;
      .column-fields {
        width: calc(1 / 2 * 100% - (1 - 1 / 2) * 4%);
        .input-wrapper {
          position: relative;
          margin-bottom: 4.3rem;
          .input {
            width: 100%;
            input {
              width: 100%;
            }
            .input-textarea {
              height: 23rem;
              padding: 2rem;
            }
          }
        }
        .check-buttons {
          .btn {
            display: flex;
            margin-bottom: 3.5rem;
            span {
              label.check {
                line-height: 0.5rem;
              }
            }
          }
        }
      }
      .privacy-btn {
        display: inline-flex;
        span {
          color: $secondary;
          font-size: $f16;
          line-height: 1;
        }
      }
    }
    .save-btn {
      text-align: right;
    }
  }
}
@media screen and (max-width: 600px) {
  .contact-us-form-container {
    form {
      .form-container {
        padding-bottom: 4rem;
        .column-fields {
          width: 100%;
        }
      }
    }
  }
}

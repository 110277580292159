@import "../../../../../../../styles/branding";
@import "../../../../../../../styles/style";
.action-wrapper {
  padding: 3.6rem 0;
  .move-program-btn {
    float: left;
    a {
      padding: 0 !important;
      button {
        background: none;
        border: none;
        color: $light-gray;
        text-transform: uppercase;
        font-size: $f12;
        text-decoration: underline;
        cursor: pointer;
        letter-spacing: 0.1rem;
      }
    }
  }
  .modal {
    .modal-inner {
      .export-list-name {
        padding-top: 3rem;
        .label {
          span {
            font-size: $f16;
          }
        }
      }
    }
  }
  .actions-toolbar {
    float: right;
    a {
      color: $light-gray;
      button {
        border: none;
        background: none;
        color: $light-gray;
        cursor: pointer;
        &:focus {
          outline: none;
        }
      }
      .remove-from-program {
        svg {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
}

@import "../../styles/branding.scss";
@import "../../styles/style.scss";
#carousel {
  margin: 0 -1.5rem;
}
.alice-carousel {
  .alice-carousel__stage-item {
    .product-item-content {
      padding: 0 1.5rem;
    }
  }
  position: unset;
  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    width: auto;
    position: absolute;
    top: 0;
    right: 0;
    span[data-area] {
      &:after {
        visibility: hidden;
      }
    }
  }
  .alice-carousel__prev-btn {
    right: 5rem;
    span[data-area] {
      &:before {
        content: "";
        background: url("../../assets/leftArrow.svg") no-repeat;
        display: block;
        width: 3rem;
        height: 3rem;
      }
    }
  }
  .alice-carousel__next-btn {
    span[data-area] {
      &:before {
        content: "";
        background: url("../../assets/rightArrowOrange.svg") no-repeat;
        display: block;
        width: 3rem;
        height: 3rem;
      }
    }
  }
}

@media screen and (max-width: $screen__xs) {
  .alice-carousel {
    .alice-carousel__prev-btn,
    .alice-carousel__next-btn {
      width: 3rem;
      height: 3rem;
      text-align: center;
      position: absolute;
      padding: 0;
      top: 0;
      right: 0;
      transform: translate(0%, 100%);
    }
    .alice-carousel__prev-btn {
      left: 0;
    }
    .alice-carousel__next-btn {
      right: 0;
      left: auto;
    }
    .alice-carousel__stage-item {
      .product-item-content {
        padding: 0;
      }
    }
  }
}

#carousel-media-gallery {
  cursor: pointer;
  position: relative;
}
.carousel-actions {
  .btn-prev,
  .btn-next {
    position: absolute;
    cursor: pointer;
    top: 50%;
    bottom: 50%;
    opacity: 0.6;
    font-size: 5rem;
    padding: 0;
    border: none;
    box-shadow: none;
    color: $light-gray;
    transition: all 0.6s ease-in-out;
    svg {
      -webkit-text-stroke: 0.1rem $white;
    }

    &:hover {
      color: $light-gray-2;
      opacity: 1;
      transition: all 0.6s ease-in-out;
    }
    &:focus {
      outline: none;
    }
  }
  .btn-prev {
    left: 0;
  }
  .btn-next {
    right: 0;
  }
}

@import "../../../../styles/branding.scss";
@import "../../../../styles/style.scss";
#productinforighttop {
  .product-title {
    h2 {
      color: $secondary;
      padding-bottom: 0;
    }
    &:has + .stock-info {
      color: 000;
    }
    p {
      margin-bottom: 2rem;
      @extend %nunito-sans-bold;
    }
  }
  .stock-info-and-brand {
    display: flex;
  }
  .stock-info {
    border-top: 0.1rem solid $primary;
    position: relative;
    margin-bottom: 2rem;
    padding-top: 2rem;
    padding-left: 1rem;
    width: 100%;
    display: flex;
    &:empty {
      display: none;
      + .short-description {
        border-top: 0;
      }
    }
    .stock-info-inner {
      width: 100%;
      .stock-info-row {
        letter-spacing: 0.12rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        p {
          font-size: $f14;
          flex: 0 0 50%;

          &:first-of-type {
            flex: 0 0 100%;
            color: $primary;
            text-transform: uppercase;
            @extend %halant-medium;
          }
          span {
            color: $secondary;
          }
        }
      }
    }
    .product-item-date {
      flex: 0 0 auto;
      p {
        font-size: 1rem;
      }
    }
  }
  .brand-image {
    width: 50%;
    position: relative;
    .brand {
      position: absolute;
      right: 0;
      bottom: 30%;
      width: 100%;
      img {
        left: 0;
        position: relative;
        width: 100%;
        padding-left: 1rem;
        box-sizing: border-box;
      }
    }
  }
  .short-description {
    border-top: 0.1rem solid $primary;
    border-bottom: 0.1rem solid $primary;
    ul {
      padding-left: 3rem;
      list-style: disc;
      li {
        font-size: $f12;
        margin: 1.5rem 0;
        position: relative;
        color: $secondary;
      }
    }
  }
  .pricing-info-button {
    .pricing-info-top {
      &:empty {
        display: none;
      }
    }
  }
}
@media screen and (min-width: $screen__l) {
  #productinforighttop {
    .product-title {
      h2 {
        max-width: 65rem;
      }
    }
  }
}

@media screen and (max-width: $screen__xs) {
  #productinforighttop {
    .stock-info {
      padding-left: 0;
      min-width: 100%;
      .stock-info-row {
        p {
          font-size: $f12;
          flex: 0 0 auto;
          &:first-of-type {
            flex: 0 0 40%;
          }
        }
      }
    }
  }
}

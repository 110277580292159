@import "../../../../styles/branding.scss";
@import "../../../../styles/style.scss";

.home-about-block {
  @extend %block-width-1350;
  padding: 14rem 11.5rem;
  text-align: center;
  box-sizing: border-box;
  .contentheader {
    h1 {
      padding-bottom: 3rem;
    }
  }
  p {
    padding: 0 11.5rem 4rem 11.5rem;
  }
}
@media screen and (max-width: $screen__m) {
  .home-about-block {
    padding: 6rem 3rem 8rem 3rem;
  }
}
@media screen and (max-width: $screen__s) {
  .home-about-block {
    p {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@import "../../../../styles/branding.scss";
@import "../../../../styles/style.scss";
.our-services-core-tabs-inner {
  @extend %block-width-1350;
  padding: 0 11.5rem;
  box-sizing: border-box;
  > div {
    display: flex;
    justify-content: center;
  }
}

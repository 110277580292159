@import "../../../../styles/branding.scss";
#productinfoleftlinks {
  display: flex;
  align-items: unset;
  text-align: left;
  margin-bottom: 5rem;

  h4 {
    font-size: $f14;
  }
  .bottom-links {
    padding: 0 0 0 2rem;
    margin: 0 1.5rem;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      width: 2rem;
      max-width: 100%;
      height: 100%;
    }
    &.sheet {
      &:before {
        background: url("../../../../assets/pdf-dark-grey.svg") no-repeat;
      }
    }
    &.package {
      &:before {
        background: url("../../../../assets/package-dark-grey.svg") no-repeat;
      }
    }
    &.image {
      &:before {
        background: url("../../../../assets/image-dark-grey.svg") no-repeat;
      }
    }
    &.detail {
      &:before {
        background: url("../../../../assets/pdf-dark-grey.svg") no-repeat;
      }
    }
    > a {
      line-height: 2;
      color: $secondary;
    }
  }
}
@media screen and (min-width: $screen__xl) {
  #productinfoleftlinks {
    display: flex;
    flex-direction: row;
    margin-bottom: 10rem;
    justify-content: center;
  }
}
@media screen and (max-width: $screen__l) {
  #productinfoleftlinks {
    display: inline-block;
    width: 100%;
    text-align: center;
    h4 {
      width: 100%;
      text-align: center;
    }
    .bottom-links {
      display: inline-block;
      margin: 0 2rem 0 0;
    }
  }
}
@media screen and (max-width: $screen__xs) {
  #productinfoleftlinks {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    h4 {
      text-align: left;
    }
    .bottom-links {
      flex: 1 0 35%;
      text-align: left;
      margin: 0.75rem 0;
    }
  }
}

@import "./../../styles/branding.scss";

.validation-message {
  position: absolute;
  top: 1.6rem;
  right: 5%;
  // z-index: 4;  // This is removed, I don't know why it was added in the first place, so leave it as a comment if needed later
  background-color: $white;
}
@media screen and (max-width: $screen__xs) {
  .validation-message {
    position: relative;
    top: unset;
    right: unset;
    text-align: center;
    margin-top: 0.5rem;
  }
}

@import "../../styles/branding.scss";
#productfreight {
  background: $gray-5;
  box-shadow: inset 0 2rem 2rem -2rem rgba(0, 0, 0, 0.5),
    inset 0 -2rem 2rem -2rem rgba(0, 0, 0, 0.5);
  .productfreight-wrapper {
    .productfreight-inner {
      display: inline-block;
      margin: 8rem 0;
      width: 100%;
    }
  }
}

@media screen and (min-width: 1025px) {
  #productfreight {
    .productfreight-wrapper {
      margin: 0 auto;
      .productfreight-inner {
        display: inline-block;
        width: 50%;
        padding-right: 2rem;
        box-sizing: border-box;
      }
    }
  }
}

@media screen and (max-width: $screen__m) {
  #productfreight {
    margin: 0 -3.6rem;
    padding: 0 3.6rem;
  }
}
@media screen and (max-width: $screen__xs) {
  #productfreight {
    padding: 3.6rem;
    .productfreight-wrapper {
      .productfreight-inner {
        margin: 0;
      }
    }
  }
}

@import "../../../../../styles/branding";
@import "../../../../../styles/style";
.edit-project {
  display: flex;
  flex-direction: row;
  margin: 6rem 0 4rem 0;
  .input-wrapper {
    .input {
      input {
        width: 43rem;
        border: none;
        border-radius: 0;
        border-bottom: 0.2rem solid $primary;
      }
    }
  }
  .buttons {
    .button {
      min-width: unset;
      font-size: 1.4rem;
      background: none;
      color: $primary;
      text-decoration: underline;
      &.delete {
        color: $secondary;
      }
    }
  }
}
//MEDIA
@media screen and (max-width: $screen__m) {
  .edit-project {
    flex-direction: column;
    .input-wrapper {
      width: 100%;
      .input {
        width: 100%;
        input {
          width: 100%;
        }
      }
    }
  }
}

@import "../../styles/branding.scss";
@import "../../styles/style.scss";
.header-top-container {
  border-bottom: 0.1rem solid $primary;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 5;
  opacity: 1;
  position: sticky;
  transition: all 0.5s ease-in-out;
  width: 100%;
  top: 0;
  display: block;
  .header-top {
    text-transform: uppercase;
    display: flex;
    position: relative;
    background-color: transparent;
    max-width: $page-max-width;
    margin: 0 auto;
    box-sizing: border-box;

    > div {
      flex: 1 0 50%;
      justify-content: space-between;
      &.left {
        display: flex;
        align-items: center;
        .telephone {
          a {
            text-decoration: none;
          }
        }
      }
      &.right {
        padding: 1.2rem 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        #welcome-customer-msg {
          font-size: $f12;
          text-transform: none;
          line-height: normal;
        }
        .my-account {
          cursor: pointer;
          margin: 0;
          padding-left: 0;
          position: relative;
          width: 16.3rem;
          text-align: center;

          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .my-account-dropdown {
            padding: 1rem 1.5rem;
            box-sizing: border-box;
            opacity: 1;
            position: absolute;
            transition: all 0.5s ease-in-out;
            background: $white;
            width: 16.3rem;
            border: 0.1rem solid $primary;
            display: flex;
            flex-direction: column;
            transform-origin: top;
            z-index: -1;
            li {
              margin-left: 0;
              font-size: 1rem;
              text-align: left;
              a {
                color: $secondary;
                display: inline-block;
                letter-spacing: normal;
                padding: 1rem 0;
                width: 100%;
              }
              &:hover {
                a {
                  color: $primary;
                }
              }
            }
          }
          span.icon {
            margin-left: 0.5rem;
          }
        }
        .my-account-false {
          .my-account-dropdown {
            top: -10rem;
            opacity: 0;
          }
        }
        .my-account-true {
          .my-account-dropdown {
            top: 2.8rem;
          }
        }

        a {
          letter-spacing: 0.12rem;
          text-decoration: none;
          color: $primary;
          margin-left: 3rem;
        }
        .btn {
          letter-spacing: 0.12rem;
          text-decoration: none;
          color: $primary;
          margin-left: 3rem;
          background: none;
          box-shadow: none;
          border: none;
          text-transform: uppercase;
          font-size: 1.2rem;
          padding: 0;
          @extend %nunito-sans-regular;
          cursor: pointer;
          &:focus {
            outline: none;
          }
        }
        .export-list-items-number {
          display: flex;
          padding: 0.5rem 2rem;
          box-sizing: border-box;
          background: $primary;
          justify-content: space-between;
          a {
            margin-left: 0;
          }
          p {
            margin-left: 0.5rem;
            color: $white;
            font-size: $f12;
          }
          svg {
            width: 1.5rem;
          }
        }
      }
      &.right.loggedin-customer {
        padding: 0;
        a {
          margin: 0;
        }
      }
    }
  }
}
@media screen and (max-width: $screen__l) {
  .header-top-container {
    .header-top {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
}

@import "../../styles/branding.scss";
@import "../../styles/style.scss";
.select2 {
  z-index: 2;
  transition: all 0.2s ease-in-out;
  //margin-bottom: 3.5rem;
  .react-select-container {
    border: 0.1rem #000;
    .react-select__control {
      border: 0.1rem solid $primary;
      cursor: pointer;
      border-radius: 2.5rem;
      .react-select__value-container--has-value {
        text-transform: uppercase;
      }
      &.react-select__control--menu-is-open {
        transition: all 0s ease-in-out;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: 2px 3px 3px #00000052;
        &.react-select__control--is-focused {
          box-shadow: 2px 3px 3px #00000052;
        }
      }
      &.react-select__control--is-focused {
        box-shadow: none;
      }
      .react-select__value-container {
        padding-left: 6.5rem;
        min-width: unset !important;
        width: 15rem;
      }
    }
    .react-select__menu {
      background-color: #fff;
      margin-top: 0;
      box-shadow: none;
      border: 0.1rem solid $primary;
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 2.5rem;
      border-bottom-right-radius: 2.5rem;
      .react-select__menu-list {
        transition: all 0.6s ease-in-out;

        .react-select__option {
          cursor: pointer;
          padding-left: 6.5rem;
          font-size: $f12;
          text-transform: uppercase;
          transition: all 0.5s ease-in-out;
          &.react-select__option--is-focused {
            background-color: $primary;
            color: #fff;
            // box-shadow: none;
            // outline: none;
          }
          &:active {
            background-color: $primary;
          }
          &:focus {
            color: #fff;
            outline: none;
          }
          &.react-select__option--is-selected {
            background-color: orange;
          }
        }
      }
      /* width */
      ::-webkit-scrollbar {
        display: none;
        width: 1rem;
        overflow: visible;
        z-index: -9;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 1rem;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: $primary;
        border-radius: 1rem;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: $light-gray;
      }
    }
  }
}
.fa-caret-down {
  &:before {
    content: "\f0d7";
    display: block;
    font-family: "fontawesome";
    width: 100%;
    height: 100%;
    font-size: 2.5rem;
  }
}
.select2 {
  .react-select-container {
    .react-select__control {
      height: 4.9rem;
      .react-select__value-container {
        min-width: 15rem;
        padding-left: 2rem;
      }
    }
  }
}
.select2 {
  .react-select-container {
    .react-select__menu {
      overflow: hidden;
      box-shadow: 2px 3px 3px #00000052;
      .react-select__menu-list {
        padding-bottom: 0;
        padding-top: 0;
        .react-select__option {
          padding-left: 2rem;
          &:last-of-type {
            border-bottom-left-radius: 2rem;
            border-bottom-right-radius: 2rem;
          }
        }
      }
    }
  }
}

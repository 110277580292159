@import "../../../../../styles/branding";
@import "../../../../../styles/style";

.middle-block-wrapper-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  height: 6rem;
  padding: 2rem;
  background: rgba(35, 30, 32, 0.15);
  border-bottom: 1px solid $primary;
  .middle-text {
    p {
      font-size: 1.4rem;
    }
  }
  .middle-btn {
    display: flex;
    flex-direction: row;
    width: 55rem;
    justify-content: space-between;
    align-items: center;
    .middle-one,
    .middle-two {
      width: calc(1 / 2 * 100% - (1 - 1 / 2) * 10%);
      display: inherit;
      label {
        line-height: 1;
        font-size: 1.4rem;
        letter-spacing: 2.4px;
        @extend %nunito-sans-extra-bold;
        color: $primary;
        margin: 0;
      }
    }
    .middle-two {
      float: right;
    }
  }
}
.middle-block-wrapper-bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 5rem;
  padding: 2rem;
  background: $gray-5;
  border-bottom-right-radius: 4.5rem;
  border-bottom-left-radius: 4.5rem;
  -webkit-box-shadow: inset 0 7px 10px -8px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: inset 0 7px 10px -8px rgba(0, 0, 0, 0.8);
  box-shadow: inset 0 7px 10px -8px rgba(0, 0, 0, 0.8);
  transition: all 0.2s ease-in-out;
  &.hidden {
    height: 0;
    padding: 0;
  }
  .box-one,
  .box-two,
  .apply-btn {
    &.visible {
      display: inline-flex !important;
      align-items: center;
    }
    p {
      float: left;
      @extend %halant-semi-bold;
      color: $primary;
      font-size: 1.4rem;
      letter-spacing: 0.14rem;
    }
    .input-wrapper {
      float: right;
    }
    .btn {
      height: 3rem;
      padding: 0;
      min-width: unset;
      padding: 0 1.5rem;
      font-size: 1.4rem;
    }
  }
  .apply-btn.visible {
    button.button {
      svg {
        width: 2.1rem !important;
        height: 2.1rem !important;
        padding: 0 1rem;
        transition: animation 0.5 ease-in-out;
        animation: spin 2s linear infinite;
        opacity: 1;
        display: block;
        margin: 0 auto;
        left: 0;
        right: 0;
      }
      @keyframes spin {
        0% {
          transform: rotateZ(0);
        }
        100% {
          transform: rotateZ(360deg);
        }
      }
    }
    .btn.remove-icon {
      svg {
        opacity: 0;
      }
    }
  }
}
//MEDIA
@media screen and (max-width: $screen__m) {
  .middle-block-wrapper-top {
    flex-direction: row;
  }
}

@media screen and (max-width: 600px) {
  .middle-block-wrapper-top {
    flex-direction: column;
    flex-wrap: unset;
    height: auto;
    .middle-btn {
      flex-direction: column;
      width: 100%;
      flex-wrap: unset;
      .middle-one,
      .middle-two {
        width: 100%;
        display: inherit;
        margin: 2rem 0;
      }
    }
  }
  .middle-block-wrapper-bottom {
    flex-direction: column;
    justify-content: center;
    height: auto;
    .box-one,
    .box-two,
    .apply-btn {
      &.visible {
        display: inline-flex !important;
        align-items: center;
        margin-bottom: 1rem;
      }
      p {
        font-size: 1.2rem;
      }
      .input-wrapper {
        float: right;
        margin-left: auto;
        margin-right: 0;
      }
      .btn {
        height: 3rem;
        padding: 0;
        min-width: unset;
        padding: 0 1.5rem;
        font-size: 1.4rem;
        margin-top: 2rem;
        width: 100%;
      }
    }
  }
}

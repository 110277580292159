@import "../../../../styles/branding.scss";
#productinforightbottom {
  .freight-quote-link-wrapper {
    transition: opacity 0.25s ease-in-out;
  }

  p {
    font-size: $f12;
  }
  .bottom-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 2rem 0 4rem 0;
    .add-to-program.two {
      width: 31.5rem;
      button.plp-button {
        color: $primary;
        border-color: $primary;
        svg {
          path {
            fill: $primary;
          }
        }
      }
    }
    .btn-save.one {
      margin-right: 3rem;
      svg {
        left: 5rem;
      }
      &:hover {
        &:before {
          left: 5rem;
        }
      }
      &.btn-saved {
        &:hover {
          &:before {
            top: 20%;
          }
        }
      }
    }
  }
  .freight-qoute {
    text-align: center;
    border-bottom: 0.2rem solid $primary;
    margin-bottom: 1rem;
    position: relative;
    cursor: pointer;
  }
  .scroll-button {
    background: transparent;
    padding: 0;
    height: auto;
    width: 100%;
    svg {
      transform: rotate(90deg);
      position: relative;
      left: auto;
      top: auto;
      width: 2rem;
    }
  }
  .add-to-program {
    position: relative;
    .program-list-container {
      .btn {
        font-size: $f16;
        &:hover {
          &:before {
            top: 1.35rem;
            left: 9%;
            height: 19px;
            width: 24px;
          }
        }
        svg {
          &:first-child {
            top: 1.35rem;
            left: 9%;
            height: 19px;
            width: 24px;
          }
        }
        &.category-options-true,
        &.category-options-false {
          position: relative;
          width: 100%;
          height: 4.9rem;
        }
        &.category-options-true {
          border-bottom: none;
          text-align: center;
        }
      }
      .program-true {
        z-index: 9;
        transition: opacity 0.5s ease-in-out;
        top: auto;
        border: none;
      }
      .program-false {
        width: 100%;
        transition: opacity 0.1s ease-in-out;
      }
    }
    .create-program {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .input-wrapper {
        flex: 1 1 50%;
        margin-right: 10%;
        .input {
          .input-program-input {
            width: 100%;
            padding: 0;
            text-align: center;
            height: 3rem;
            border: none;
            border-bottom: 1px solid #eee;
            border-radius: 0;
            transition: none;
            &::placeholder {
              font-size: $f12;
            }
          }
        }
      }
      .btn-orange {
        &.create-list {
          font-size: $f14;
          flex: 1 1 40%;
          height: 3rem;
          padding: 0 1rem;
          min-width: unset;
          width: 9rem;
        }
      }
    }
  }
}
@media screen and (min-width: $screen__l) and (max-width: $screen__ml) {
  #productinforightbottom {
    p {
      font-size: $f14;
    }
    .bottom-buttons {
      margin: 1rem 0;
    }
  }
}
@media screen and (min-width: 1025px) and (max-width: $screen__ml) {
  #productinforightbottom {
    .bottom-buttons {
      flex-direction: column;
      .btn-save.btn-white-orange-border {
        margin: 0 auto 1.5rem auto;
      }
      .add-to-program.two {
        margin: 0 auto;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  #productinforightbottom {
    .bottom-buttons {
      .btn-save.btn-white-orange-border {
        margin: 0 auto 1.5rem auto;
      }
      .add-to-program.two {
        margin: 0 auto;
        button.category-options-false {
          padding: 0 2rem 0 6rem;
        }
        button.category-options-true {
          padding: 0 2rem;
        }
      }
    }
  }
}

@import "../../../styles/branding.scss";
@import "../../../styles/style.scss";
#homepage {
  .home-brands {
    border-bottom: 0.1rem solid $secondary;
    padding: 2rem 0 2rem 0;
    box-sizing: border-box;
    > div {
      @extend %block-width-1350;
      // display: flex;
      // overflow-x: auto;
      // justify-content: space-between;
      .brand-logo {
        text-align: center;
        img {
          width: 16rem;
          padding: 0 2rem;
        }
      }
    }
  }
  .home-carousel {
    position: relative;
    margin-top: -143px;
    #carousel {
      margin: 0 auto;
    }
    .carousel-navigation {
      position: absolute;
      bottom: 0;
      right: 0;
      &.active-slide-2,
      &.active-slide-3 {
        button.btn-number {
          color: black;
        }
        button.btn-number-active {
          border-color: black;
        }
      }
    }
    .alice-carousel__dots {
      position: absolute;
      left: 0;
      bottom: 0rem;
      right: 0;
      margin: 0 auto;
      .alice-carousel__dots-item {
        background-color: #fff;
        border: 1px solid #000;
        &.__active {
          background-color: #000;
          border: 1px solid #fff;
        }
      }
    }
  }
  .carousel-content {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 11rem 0 9rem 0;
    box-sizing: border-box;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      // background: linear-gradient(rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.5));
    }
    .carousel-inner-content {
      @extend %block-width-1350;
      h4,
      h1,
      p {
        color: $white;
      }
      h1 {
        font-size: 12rem;
        padding: 0;
      }
      h4 {
        border-left: 0.2rem solid $white;
        padding-left: 2rem;
      }
      p {
        margin-bottom: 3rem;
        line-height: 3.2rem;
      }
      .carousel-text {
        position: absolute;
        bottom: 4rem;
        z-index: 1;
      }
    }
  }
}
@media screen and (min-width: $screen__l) {
  #homepage {
    .carousel-content {
      min-height: 72.8rem;
    }
  }
}
@media screen and (max-width: $screen__l) {
  #homepage {
    .carousel-content {
      min-height: 61rem;
    }
  }
}
@media screen and (max-width: $screen__m) {
  #homepage {
    .home-brands {
      > div {
        // display: flex;
      }
    }
  }
}
@media screen and (max-width: $screen__s) {
  #homepage {
    .carousel-content {
      .carousel-inner-content {
        .carousel-text {
          width: 60%;
        }
      }
    }
    // .home-brands {
    //   > div {
    //     .brand-logo {
    //       width: calc(33.33% - 5rem);
    //       &:nth-child(4) {
    //         display: none;
    //       }
    //       &:nth-child(5) {
    //         display: none;
    //       }
    //     }
    //   }
    // }
    .map {
      margin-top: 4rem;
    }
  }
}
@media screen and (max-width: $screen__xs) {
  #homepage {
    .home-carousel {
      .carousel-content {
        .carousel-inner-content {
          .carousel-text {
            width: 100%;
          }
        }
      }
      .carousel-navigation {
        display: none;
      }
    }

    // .home-brands {
    //   > div {
    //     .brand-logo {
    //       width: calc(50% - 3rem);
    //       &:nth-child(3) {
    //         display: none;
    //       }
    //     }
    //   }
    // }
    .home-services {
      margin-bottom: 4rem;
    }
  }
}

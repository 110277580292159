@import "../../../../styles/branding";
@import "../../../../styles/style";
.account-form-edit-container {
  form {
    margin: 3.5rem 12rem 13rem 12rem;
    .form-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      border-bottom: 0.1rem solid $primary;
      .column-fields {
        width: calc(1 / 2 * 100% - (1 - 1 / 2) * 4%);
        .input-wrapper {
          margin-bottom: 4.3rem;
          .input {
            width: 100%;
            input {
              width: 100%;
            }
          }
        }
        .check-buttons {
          .btn {
            display: flex;
            margin-bottom: 3.5rem;
            align-items: center;
            span {
              label.check {
                line-height: 0.5rem;
                display: inline;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: $screen__s) {
  .account-form-edit-container {
    h1 {
      font-size: 2.4rem;
    }
    form {
      margin: 0;
      .form-container {
        margin-top: 4.3rem;
        .column-fields {
          width: 100%;
          .input-wrapper {
            margin-bottom: 4.3rem;
            .input {
              width: 100%;
              input {
                width: 100%;
              }
            }
          }
        }
        .switch-container {
          span.label {
            width: 100%;
            min-width: 25rem;
          }
        }
      }
    }
  }
}

@import "../../styles/branding.scss";
@import "../../styles/style.scss";

.compound-slider {
  position: relative;
  min-height: 7rem;
  max-width: 85%;
  left: 0;
  right: 0;
  margin: 0 auto;
  .range {
    position: relative;
    width: 100%;
    height: 80;
    border: 0.1rem solid $primary;
  }
  .rail {
    position: absolute;
    width: 100%;
    height: 1.5rem;
    margin-top: 2.5rem;
    border-radius: 1.3rem;
    background-color: $light-gray;
  }
  .tracks {
    .track {
      position: absolute;
      height: 1.5rem;
      z-index: 1;
      margin-top: 2.5rem;
      background-color: $secondary;
      border-radius: 1.3rem;
      cursor: "pointer";
    }
  }
  .handles {
    .handle {
      position: absolute;
      margin-left: -1.5rem;
      margin-top: 2rem;
      z-index: 2;
      width: 2.5rem;
      height: 2.5rem;
      border: 0;
      text-align: center;
      cursor: pointer;
      border-radius: 50%;
      background-color: $primary;
      color: #333;
      .text {
        @extend %nunito-sans-regular;
        font-size: 1.2rem;
        margin-top: -1.5rem;
        color: $primary;
      }
    }
  }
  .ticks {
    .tick-first {
      position: absolute;
      margin-top: 5.2rem;
      margin-left: -0.5rem;
      width: 0.1rem;
      height: 0.8rem;
      background-color: "silver";
    }
    .tick-second {
      position: absolute;
      margin-top: 6rem;
      font-size: 1rem;
      text-align: center;
    }
  }
}
.rangefield-content {
  display: flex;
  align-items: center;
  justify-content: center;
  .input-wrapper {
    margin: 0 2rem;
  }
  span {
    text-transform: uppercase;
    color: $secondary;
    padding: 0;
    font-size: 1.2rem;
  }
}

@import "../../../styles/branding.scss";
@import "../../../styles/style.scss";
.our-services-container {
  .our-services-inner-content {
    h3,
    h1 {
      text-align: center;
    }
    h3 {
      &::after {
        right: 0;
        margin: 0 auto;
      }
    }
    .breadcrumbs {
      @extend %block-width-1350;
    }
    .our-services-left {
      width: calc(50% - 1.5rem);
      img {
        width: 100%;
      }
    }
    .our-services-right {
      padding: 5rem 0;
      box-sizing: border-box;
      width: calc(50% - 1.5rem);
      h1,
      h3 {
        text-align: left;
        &:after {
          right: auto;
        }
      }
    }
    .our-services-top-block {
      @extend %block-width-1350;
      margin-top: 10rem;
      margin-bottom: 15rem;
      padding: 0 11.5rem;
      box-sizing: border-box;

      > div {
        display: flex;
        justify-content: space-between;
      }
    }
    .our-services-core {
      @extend %gray-background-with-inner-shadow;
      padding-bottom: 15rem;
      h1 {
        padding-bottom: 5rem;
      }
      .our-services-core-tabs {
        margin-bottom: 14rem;
        border-top: 0.1rem solid $primary;
        border-bottom: 0.1rem solid $primary;
        .our-services-core-tabs-inner {
          .our-services-core-tabs-item {
            width: calc(20% - 1.5rem);
            .usp {
              width: 20rem;
              padding: 0;
              cursor: pointer;
              svg {
                width: 10rem;
                height: auto;
                margin: 0;
                padding: 2.2rem 4.7rem;
              }
              .title {
                display: none;
                h3 {
                  font-size: $f16;
                  color: $white;
                  @extend %halant-regular;
                  padding: 1rem;
                  margin-top: 0;
                  line-height: 1.8rem;
                  letter-spacing: 0.16rem;
                  &:after {
                    display: none;
                  }
                }
              }
            }
          }
          .our-services-core-tabs-item.active {
            .usp {
              opacity: 0.9;
              transform: scale(1.05);
              position: relative;
              transition: all 1.5s ease-out;
              svg {
                background: $white;
                border: 0.3rem solid $primary;
              }
              .title {
                display: block;
                position: absolute;
                cursor: pointer;
                background: $primary;
                bottom: -5.5rem;
                box-shadow: 0 0.3rem 0.6rem #00000029;
                border-radius: 0 0 2.5rem 2.5rem;
              }
            }
          }
        }
      }
      .our-services-core-blocks {
        padding: 0 11.5rem;
        box-sizing: border-box;
        .our-services-content {
          transition: all 1.5s ease-out;
          opacity: 0;
          height: 0;
          overflow: hidden;
          > div {
            display: flex;
            justify-content: space-between;
            .our-services-right {
              h3 {
                padding-bottom: 4rem;
              }
            }
            img {
              border: 0.2rem solid $black;
            }
          }
          &.our-services-active {
            opacity: 1;
            height: auto;
            transform: scale(1.1);
          }
        }
        @extend %block-width-1350;
        h3 {
          &:after {
            display: none;
          }
        }
      }
    }
    .our-services-above-footer {
      padding-top: 15rem;
      padding-bottom: 15rem;
      .our-services-above-footer-inner {
        @extend %block-width-1350;
        box-sizing: border-box;
        .gray-box {
          text-align: center;
          background-size: cover;
          background-repeat: no-repeat;
          padding: 4rem;
          box-sizing: border-box;
          .gray-box-inner {
            margin: 0;
            padding: 7rem 0;
            box-sizing: border-box;
            border: 0.1rem solid $white;
            h1,
            h3 {
              color: $white;
            }
            h1 {
              padding-bottom: 5rem;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__m) {
  .our-services-container {
    .our-services-inner-content {
      .our-services-top-block {
        padding: 0 3rem;
      }
      .our-services-core {
        .our-services-core-tabs-inner {
          padding: 0 3rem;
        }
        .our-services-core-blocks {
          .our-services-content {
            .our-services-left {
              padding: 5rem 0;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__s) {
  .our-services-container {
    .our-services-inner-content {
      .our-services-top-block {
        margin-top: 5rem;
        margin-bottom: 5rem;
        h3 {
          text-align: left;
          &::after {
            right: auto;
          }
        }
        h1 {
          text-align: left;
        }
        > div {
          flex-direction: column;
          div {
            width: 100%;
            &:first-child {
              margin-bottom: 3rem;
            }
          }
          .our-services-right {
            padding: 0;
          }
        }
      }
      .our-services-core {
        padding: 5rem 0;
        .our-services-core-tab-container {
          height: 24.1rem;
          display: flex;
          // overflow-x: auto;
          // overflow-y: hidden;
          .our-services-core-tabs {
            margin-bottom: 9rem;
            width: 100%;
            .our-services-core-tabs-item {
              width: calc(33.33% - 1.5rem);
              &:nth-child(4) {
                display: none;
              }
              &:nth-child(5) {
                display: none;
              }
            }
          }
        }
        .our-services-core-blocks {
          margin-top: 5rem;
          padding: 0 3rem;
          .our-services-content {
            transform: scale(1.1);
            > div {
              flex-direction: column;
              div {
                width: 100%;
                &:first-child {
                  margin-bottom: 3rem;
                }
              }
              .our-services-left {
                width: 90%;
                margin: 0 auto;
              }
              .our-services-right {
                padding: 0;
              }
            }
          }
          .our-services-content.our-services-active {
            transform: none;
          }
        }
      }
      .our-services-above-footer {
        padding-top: 5rem;
        padding-bottom: 5rem;
        .our-services-above-footer {
          .gray-box {
            margin-bottom: 3rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .our-services-container {
    .our-services-inner-content {
      .our-services-core {
        .our-services-core-tab-container {
          .our-services-core-tabs {
            .our-services-core-tabs-item {
              width: calc(50% - 1.5rem);
              &:nth-child(3) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .our-services-container {
    .our-services-inner-content {
      .our-services-top-block {
        > div {
          .our-services-right {
            h1 {
              padding-bottom: 3rem;
            }
          }
        }
      }
      .our-services-core {
        .our-services-core-tab-container {
          .our-services-core-tabs {
            .our-services-core-tabs-inner {
              .our-services-core-tabs-item {
                .usp {
                  width: 13.6rem;
                  svg {
                    padding: 2.2rem 1.5rem;
                  }
                  .title {
                    width: 100%;
                    h3 {
                      font-size: $f12;
                    }
                  }
                }
              }
            }
          }
        }
        .our-services-core-blocks {
          margin-top: 2rem;
          .our-services-content {
            .our-services-left {
              padding: 0;
            }
          }
        }
      }

      .our-services-above-footer {
        .our-services-above-footer-inner {
          .gray-box {
            padding: 2rem;
            .gray-box-inner {
              padding: 5rem 0;
            }
          }
        }
      }
    }
  }
}

@import "../../../../../styles/branding.scss";
#pricebox {
  display: flex;
  margin: 4.5rem 0;
  .price-label {
    flex: 0 0 10%;
    padding-right: 30px;
    h4 {
      font-size: $f14;
    }
  }
  .price-info-wrapper {
    flex: 0 0 80%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .price-info-top {
      .price-info-row {
        .row {
          font-size: $f18;
          padding: 1.2rem 3rem;
          &.one {
            background: #e8e8e8;
            border-top-left-radius: 2.5rem;
            border-bottom-left-radius: 2.5rem;
          }
          &.two {
            background: #989898;
          }
          &.three {
            background: #484848;
            border-top-right-radius: 2.5rem;
            border-bottom-right-radius: 2.5rem;
          }
        }
      }
    }
    .price-info-bottom {
      font-size: $f18;
      padding: 1.2rem 3rem;
      background: #e8e8e8;
    }
  }
}
@media screen and (min-width: $screen__m) and (max-width: $screen__ml) {
  #pricebox {
    margin: 1rem 0;
  }
}
@media screen and (max-width: $screen__m) {
  #pricebox {
    display: flex;
    flex-direction: column;
    .price-info-wrapper {
      flex: 0 0 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      .price-info-bottom {
        font-size: $f18;
        padding: 1.2rem 3rem;
        background: #e8e8e8;
      }
      .pricing-info-button {
        .pricing-info-top {
          display: flex;
          flex-direction: column;
          .pricing-info {
            width: 100%;
          }
          .pricing-info-first {
            border-radius: 2.3rem 2.3rem 0 0;
          }
          .pricing-info-middle {
            border-radius: 0;
          }
          .pricing-info-last {
            border-radius: 0 0 2.3rem 2.3rem;
          }
        }
        .pricing-info-bottom {
          margin-top: 1rem;
          border-radius: 2.3rem;
        }
        &.pricing-info-color {
          width: 32rem;
        }
      }
    }
  }
}

@import "../../../styles/branding.scss";
@import "../../../styles/style.scss";

/*Privacy Policy*/
.page-main-content.page-4 {
  padding-top: 0;
  .privacy-policy {
    .privacy-policy-inner-content {
      padding-bottom: 9rem;
      box-sizing: border-box;
      h1 {
        position: relative;
        padding-bottom: 3rem;
        margin-bottom: 5rem;
        line-height: 3.2rem;
        &:after {
          @extend %title-bottom-border;
        }
      }
      h3 {
        padding: 0;
        margin-top: 3rem;
        line-height: 3.2rem;
        &:after {
          display: none;
        }
      }
      p {
        line-height: 3.2rem;
      }
      a {
        text-decoration: underline;
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .page-main-content.page-4 {
    .privacy-policy {
      .privacy-policy-inner-content {
        h1 {
          padding-bottom: 2rem;
          margin-bottom: 3rem;
        }
      }
    }
  }
}

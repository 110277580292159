@import "../../styles/branding.scss";
@import "../../styles/style.scss";

.close-btn {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  border: 0.1rem solid $primary;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  display: inline-block;
  &:after,
  &:before {
    position: absolute;
    left: 1.1rem;
    top: 0.4rem;
    content: " ";
    height: 1.6rem;
    width: 0.1rem;
    background-color: $primary;
  }
  &:after {
    transform: rotate(-45deg);
  }
  &:before {
    transform: rotate(45deg);
  }
  &:focus {
    outline: none;
  }
}

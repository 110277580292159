@import "../../../styles/branding.scss";
@import "../../../styles/style.scss";
.about-us-container {
  .about-us-inner-content {
    h3,
    h1 {
      text-align: center;
    }
    h3 {
      &::after {
        right: 0;
        margin: 0 auto;
      }
    }
    .breadcrumbs {
      @extend %block-width-1350;
    }
    .about-us-top-block {
      @extend %block-width-1350;
      margin-top: 10rem;
      margin-bottom: 15rem;
      padding: 0 11.5rem;
      box-sizing: border-box;
      .about-us-inner-bottom {
        display: flex;
        justify-content: space-between;
        p {
          width: calc(50% - 1.5rem);
          img {
            width: 100%;
          }
        }
      }
    }
    .about-us-brands {
      @extend %gray-background-with-inner-shadow;
      h1 {
        padding-bottom: 10rem;
      }
      .about-us-brands-bottom {
        @extend %block-width-1350;

        .about-us-brands-images-dynamic {
          margin-bottom: 9rem;
          > div {
            // display: flex;
            // justify-content: space-between;
            // overflow-x: auto;
            width: 100%;
            img {
              width: 16rem;
              padding: 0 2rem;
            }
          }
        }
      }
      .about-us-brands-button {
        text-align: center;
      }
    }
    .about-us-above-footer {
      @extend %block-width-1350;
      padding-top: 15rem;
      padding-bottom: 15rem;
      box-sizing: border-box;
      .about-us-above-footer-inner {
        display: flex;
        justify-content: space-between;
        .gray-box {
          text-align: center;
          background-size: cover;
          background-repeat: no-repeat;
          padding: 4rem;
          box-sizing: border-box;
          width: calc(50% - 1.5rem);
          .gray-box-inner {
            padding: 6rem;
            border: 0.1rem solid $white;
            h1,
            h3 {
              color: $white;
            }
            h1 {
              padding-bottom: 5rem;
            }
            h3 {
              &:after {
                background: $white;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__m) {
  .about-us-container {
    .about-us-inner-content {
      .about-us-top-block {
        padding: 0 3rem;
      }
      .about-us-brands {
        .about-us-brands-bottom {
          .about-us-brands-images-dynamic {
            > div {
              // display: flex;
              div {
                margin-bottom: 4rem;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__s) {
  .about-us-container {
    .about-us-inner-content {
      .about-us-top-block {
        margin-top: 2rem;
        margin-bottom: 5rem;
        h3 {
          text-align: left;
          &::after {
            right: auto;
          }
        }
        h1 {
          text-align: left;
        }
        .about-us-inner-bottom {
          flex-direction: column;
          p {
            width: 100%;
            &:first-child {
              margin-bottom: 3rem;
            }
          }
        }
      }
      .about-us-brands {
        padding: 5rem 0;
        // .about-us-brands-bottom {
        //   .about-us-brands-images {
        //     > div {
        //       .brand-logo {
        //         width: calc(33.33% - 5rem);
        //         &:nth-child(4) {
        //           display: none;
        //         }
        //         &:nth-child(5) {
        //           display: none;
        //         }
        //       }
        //     }
        //   }
        // }
      }
      .about-us-above-footer {
        padding-top: 5rem;
        padding-bottom: 5rem;
        .about-us-above-footer-inner {
          flex-direction: column;
          .gray-box {
            width: 100%;
            margin-bottom: 3rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .about-us-container {
    .about-us-inner-content {
      // .about-us-brands {
      //   .about-us-brands-bottom {
      //     .about-us-brands-images {
      //       > div {
      //         .brand-logo {
      //           width: calc(50% - 3rem);
      //           &:nth-child(3) {
      //             display: none;
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
      .about-us-above-footer {
        .about-us-above-footer-inner {
          .gray-box {
            padding: 2rem;
            .gray-box-inner {
              padding: 3rem;
              a {
                display: flex;
                justify-content: center;
                font-size: $f14;
                text-decoration: none;
                button {
                  font-size: $f14;
                }
              }
            }
          }
        }
      }
    }
  }
}

@import "../../../styles/branding.scss";
@import "../../../styles/style.scss";
#searchpage {
  .search-items-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 7rem;
    .product-item-content {
      padding: 0 1rem;
      width: calc(1 / 4 * 100% - (1 - 1 / 4) * 2.22%);
    }
    &:after {
      content: "";
      flex: auto;
    }
    .react-select__control {
      width: 15.8rem;
      height: 3.1rem;
      min-height: 100%;
      input {
        padding: 0 2rem 0 4.8rem;
        height: 100%;
        width: 16rem;
        font-size: 1.4rem;
        cursor: pointer;
      }
      .react-select__value-container {
        padding-left: 3.3rem;
        height: auto;
      }
      .react-select__indicators {
        height: 100%;
      }
      .react-select__placeholder {
        transform: none;
      }
      .react-select__menu {
        max-height: 25rem;
      }
    }
  }

  .list-more-products-btn {
    margin: 7rem 0;
    svg {
      padding: 0 1rem;
      transition: animation 0.5 ease-in-out;
      animation: spin 2s linear infinite;
      opacity: 1;
    }
    @keyframes spin {
      0% {
        transform: rotateZ(0);
      }
      100% {
        transform: rotateZ(360deg);
      }
    }
  }
  .list-more-products-btn.remove-icon {
    svg {
      opacity: 0;
    }
  }
}
.toolbar-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 0.2rem solid $primary;
  align-items: center;
  padding-bottom: 3rem;
  #toolbar,
  .toolbar-search-container {
    width: calc(1 / 2 * 100% - (1 - 1 / 2) * 6%);
    padding: 0;
    border: none;
    .select2 {
      margin: 0;
    }
  }
}
@media screen and (max-width: $screen__ml) {
  #searchpage {
    .search-items-wrapper {
      .product-item-content {
        width: calc(1 / 3 * 100% - (1 - 1 / 3) * 3%);
      }
    }
    .toolbar-search-container {
      display: flex;
      flex-wrap: wrap;
      .toolbar-search {
        width: 100%;
        &.count-items {
          order: 3;
          text-align: center;
          padding: 2rem 0;
        }
      }
    }
  }
}
@media screen and (max-width: $screen__m) {
  #searchpage {
    .search-items-wrapper {
      justify-content: space-between;

      .product-item-content {
        width: calc(1 / 2 * 100% - (1 - 1 / 2) * 6%);
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  #searchpage {
    .search-items-wrapper {
      .product-item-content {
        width: 100%;
        padding: 0;
      }
    }
  }
  .toolbar-container {
    width: 100%;
    flex-direction: column;
    #toolbar,
    .toolbar-search-container {
      width: 100%;
    }
  }
}

@import "../styles/branding.scss";
@import "../styles/style.scss";

.category-container {
  .plp-container {
    width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;
    display: flex;
    @media screen and (min-width: 769px) {
      &.sidebar-visible {
        #sidebar {
          padding: 3rem 1rem 0 3rem;
          box-sizing: border-box;
          .sidebar-inner-content {
            padding-right: 1rem;
            max-height: 100vh;
            overflow-y: scroll;
            overflow-x: hidden;
            &::-webkit-scrollbar-corner {
              background-color: $gray-5;
            }
            &::-webkit-scrollbar {
              width: 0.75rem;
            }
          }
        }
      }
    }
    #sidebar {
      flex: 2.2 0;
      box-sizing: border-box;
      background: $gray-5;
      box-shadow: 0.3rem 0 0.6rem #00000029;
      transform: translateX(-500%);
      position: absolute;
      z-index: 2;
      &.slide-enter {
        transition: transform 1s ease-in-out;
        position: relative;
        padding: 3rem 1rem 0 3rem;
        box-sizing: border-box;
      }
      &.slide-enter-done {
        transform: translateX(0);
        transition: transform 1s ease-in-out;
        position: relative;
        padding: 3rem 1rem 0 3rem;
        box-sizing: border-box;
      }
      &.slide-enter-active {
        transform: translateX(0);
        transition: transform 1s ease-in-out;
        position: relative;
        padding: 3rem 1rem 0 3rem;
        box-sizing: border-box;
      }
      &.slide-exit {
        transition: transform 1s ease-in-out;
        z-index: 2;
        position: relative;
        padding: 3rem 1rem 0 3rem;
        box-sizing: border-box;
      }
      &.slide-exit-done {
        transform: translateX(-500%);
        transition: transform 1s ease-in-out;
        position: absolute;
        padding: 3rem 1rem 0 3rem;
        box-sizing: border-box;
      }
      &.slide-exit-active {
        transform: translateX(-500%);
        transition: transform 1s ease-in-out;
        position: relative;
        padding: 3rem 1rem 0 3rem;
        box-sizing: border-box;
      }
    }

    #listing {
      flex: 8 0;
      padding: 3rem 0 0 0;
      box-sizing: border-box;
      transition: all 1s ease-in-out;
      // &.listing-width100 {
      //   flex: 1 0 100%;
      // }
      &.slide-enter {
        transition: all 1s ease-in-out;
      }
      &.slide-enter-done {
        width: 100%;
        transition: all 1s ease-in-out;
      }
      &.slide-enter-active {
        width: 100%;
        transition: all 1s ease-in-out;
      }
      &.slide-exit {
        transition: all 1s ease-in-out;
        z-index: 2;
        padding: 3rem 0 0 3rem;
      }
      &.slide-exit-done {
        width: 100%;
        transition: all 1s ease-in-out;
        padding: 3rem 0 0 3rem;
      }
      &.slide-exit-active {
        width: 100%;
        transition: all 1s ease-in-out;
        padding: 3rem 0 0 3rem;
      }
    }
    &.listing-width100 {
      display: flex;
      flex: 1;
    }
  }
  @media screen and (max-width: $screen__m) {
    .plp-container {
      &.sidebar-visible {
        #listing {
          flex: 1 0 50%;
        }
        #sidebar {
          flex: 1.1 0;
          padding: 3rem 1rem 0 1rem;
        }
      }
    }
  }
  @media screen and (max-width: $screen__s) {
    .plp-container {
      &.listing-width100 {
        display: flex;
        flex: 1;
      }
      &.sidebar-visible {
        #sidebar {
          flex: 1 0 100%;
          padding: 3rem 2rem 0 2rem;
        }
      }
      #listing.listing-width80 {
        display: none;
      }
    }
  }
}

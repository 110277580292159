@import "../../styles/branding.scss";
@import "../../styles/style.scss";
#productrelated {
  margin-left: auto;
  margin-right: auto;
  max-width: $page-max-width;
  position: relative;
  margin: 17rem auto 17rem auto;
  transition: all 0.2s ease-in-out;
  h2 {
    margin-bottom: 0;
  }
  .related-item {
    padding: 2rem;
  }
  .alice-carousel {
    border-top: 0.2rem solid $primary;
    .alice-carousel__wrapper {
      margin-top: 3rem;
      overflow: hidden;
      .alice-carousel__stage-item {
        padding-bottom: 7rem;
      }
      ul {
        li {
          .product-item-content {
            width: unset;
          }
        }
      }
    }
  }
  .carousel-actions {
    .btn-prev,
    .btn-next {
      position: absolute;
      cursor: pointer;
      top: 50%;
      bottom: 50%;
      opacity: 0.6;
      font-size: 5rem;
      padding: 0;
      border: none;
      box-shadow: none;
      color: $light-gray;
      transition: all 0.6s ease-in-out;
      svg {
        -webkit-text-stroke: 0.1rem $white;
      }

      &:hover {
        color: $light-gray-2;
        opacity: 1;
        transition: all 0.6s ease-in-out;
      }
      &:focus {
        outline: none;
      }
    }
    .btn-prev {
      left: 0;
    }
    .btn-next {
      right: 0;
    }
  }
  .react-select__control {
    width: 15.8rem;
    height: 3.1rem;
    min-height: 100%;
    input {
      padding: 0 2rem 0 4.8rem;
      height: 100%;
      width: 16rem;
      font-size: 1.4rem;
      cursor: pointer;
    }
    .react-select__value-container {
      padding-left: 3.3rem;
      line-height: auto;
      height: auto;
      top: auto;
      transform: none;
    }
    .react-select__indicators {
      height: 100%;
    }
    .react-select__placeholder {
      transform: none;
    }
    .react-select__menu {
      max-height: 25rem;
    }
  }
}
@media screen and (max-width: 1360px) and (min-width: 1025px) {
  #productrelated {
    padding: 0 3rem;
    box-sizing: border-box;
    .alice-carousel {
      .alice-carousel__prev-btn {
        right: 7.5rem;
      }
      .alice-carousel__next-btn {
        right: 1.5rem;
      }
    }
  }
}
@media screen and (max-width: $screen__s) {
  #productrelated {
    margin: 6rem 0;
  }
}
@media screen and (min-width: $screen__s) {
  .alice-carousel__wrapper {
    width: 100%;
    ul {
      li {
        .product-item-content {
          width: 30rem;
          margin: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $screen__xs) {
  #productrelated {
    h2 {
      text-align: center;
      padding-bottom: 7rem;
    }
    .alice-carousel {
      .alice-carousel__prev-btn {
        padding-top: 2rem;
        left: 35%;
      }
      .alice-carousel__next-btn {
        right: 35%;
        padding-top: 2rem;
      }
    }
    #carousel-related {
      .alice-carousel__wrapper {
        overflow: hidden;
      }
    }

    .product-item-buttons {
      border: 0;
      &.product-item-part {
        margin-bottom: 2rem;
        border: 0;
        .save-action {
          margin-bottom: 1rem;
          .btn.btn-with-left-icon {
            text-align: left;
          }
        }
        .program-list-container {
          .checkbox-container {
            margin-bottom: 1.2rem;
          }
          .program-true {
            .wrapper-inside-program {
              padding: 1.5rem;
            }
          }
        }
      }
    }
  }
}

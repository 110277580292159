/*Color variables*/
$primary: rgb(232, 133, 2);
$secondary: #62666a;
$light-gray: #c4c6c9;
$light-gray-2: #707070;
$gray-0: #5d5d5d;
$gray-1: rgba(0, 0, 0, 0.37);
$gray-2: rgba(0, 0, 0, 0.32);
$gray-3: #bfbfbf;
$gray-4: rgba(0, 0, 0, 0.13);
$gray-5: #f0f0f0;
$white: #fff;
$success: #69b271;
$info: #5d5d5d;
$danger: #c72f2f;
$shadow-color: #00000052;
$black: #231e20;

/*Font variables*/
$f42: 4.2rem;
$f36: 3.6rem;
$f24: 2.4rem;
$f21: 2.1rem;
$f18: 1.8rem;
$f16: 1.6rem;
$f14: 1.4rem;
$f12: 1.2rem;

/*Responsive variables*/
$screen__xxs: 375px;
$screen__xs: 480px;
$screen__s: 768px;
$screen__m: 1024px;
$screen__ml: 1200px;
$screen__l: 1440px;
$screen__xl: 1920px;
$screen__2k: 2560px;

/*CMS Layout*/
$page-max-width: 1350px;

@import "../../../../styles/branding.scss";
@import "../../../../styles/style.scss";
.filters {
  position: sticky;
  top: 14rem;
  margin-bottom: 14rem;
  h4 {
    svg {
      height: 100%;
    }
  }
  .rotate-arrow {
    h4 {
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // &:after {
      //   content: "\f0d8";
      //   font-family: "fontawesome";
      //   position: absolute;
      //   right: 0;
      //   top: 0;
      //   z-index: 2;
      //   font-size: 2rem;
      //   text-align: center;
      //   cursor: pointer;
      //   line-height: 1.5;
      // }
    }
  }
  .rotate-false {
    h4 {
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: space-between;
      // &:after {
      //   content: "\f0d7";
      //   font-family: "fontawesome";
      //   position: absolute;
      //   right: 0;
      //   top: 0;
      //   z-index: 2;
      //   font-size: 2rem;
      //   text-align: center;
      //   cursor: pointer;
      //   line-height: 1.5;
      // }
    }
  }

  .filter-content {
    .filter-inner-content.overflow-on {
      overflow-y: scroll;
      max-height: 17rem;
      &::-webkit-scrollbar-track {
        height: 1.5rem;
        background-color: transparent;
      }
      &::-webkit-scrollbar {
        width: 1rem;
        height: 1.5rem;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 0.5rem;
        background-color: rgba(112, 112, 112, 0.5);
        height: 1.5rem;
      }
    }
  }
  .filter-inner-content {
    ul {
      padding-left: 0;
      margin: 0;
      .filter-list {
        margin-bottom: 1rem;
        position: relative;
        text-transform: uppercase;
        color: $secondary;
        display: flex;
        align-items: center;
        letter-spacing: 0.12rem;
        cursor: pointer;
        span.icon {
          position: absolute;
          right: 0.3rem;
        }
      }
      li.category-dropdown {
        .close-btn {
          margin-right: 0.4rem;
        }
        &.category-with-close-btn {
          span {
            color: $primary;
            @extend %nunito-sans-bold;
          }
        }
      }
      li.brand-dropdown {
        padding-left: 3rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        margin-bottom: 3rem;
        position: unset;
        > span.filter-list-name {
          margin-bottom: 2rem;
        }
        > span.icon {
          // &:after {
          //   content: "\f0fe";
          //   font-family: "fontawesome";
          //   color: $primary;
          //   z-index: 2;
          //   cursor: pointer;
          //   font-size: $f16;
          //   line-height: 0;
          // }
        }
        .filter-list-name {
          width: 100%;
        }
        ul {
          // padding-left: 3rem;
          box-sizing: border-box;
          width: 100%;
          li {
            .close-btn {
              width: 2.7rem;
            }
            &.category-with-close-btn {
              margin-left: -3rem;
            }
          }
        }
      }
      li.brand-dropdown.children-false {
        margin-bottom: 0;
      }
      li.brand-dropdown.children-true {
        > span.icon {
          // &:after {
          //   content: "\f146";
          //   font-family: "fontawesome";
          //   color: $primary;
          //   z-index: 2;
          //   cursor: pointer;
          //   font-size: $f16;
          //   line-height: 0;
          // }
        }
      }
    }
  }
  .filter-inner-content.hidden {
    display: none;
  }
  #filter.rotate-arrow {
    h4 {
      &:after {
        transform: none;
      }
    }
  }
}

@import "../../styles/branding";

#customer-pages {
  flex: 0 0 83%;
  margin-left: 3rem;
}

@media screen and (max-width: $screen__m) {
  #customer-pages {
    flex: 0 0 75%;
  }
}

@import "../styles/branding.scss";
@import "../styles/style.scss";

#footer {
  background-color: $white;
  border-top: 0.3rem solid $primary;
  .footer-top-content {
    @extend %block-width-1350;
    display: flex;
    flex-wrap: wrap;
    padding-top: 3rem;
    padding-bottom: 3rem;
    box-sizing: border-box;
    position: relative;
    &:before {
      height: 1rem;
      background: $primary;
      top: 0;
      position: absolute;
      content: "";
      width: 8.5rem;
    }
  }
  .logo {
    width: 45%;
    svg {
      height: 10rem;
    }
  }
  .top-right {
    width: 55%;
    div {
      height: 100%;

      ul {
        display: flex;
        margin: 0;
        height: 100%;
        align-items: center;

        li {
          width: 25%;
          text-transform: uppercase;
          @extend %nunito-sans-bold;
          text-align: right;
          a {
            text-decoration: none;
            font-size: $f16;
          }
        }
      }
    }
  }
  .footer-bottom-content {
    background-color: $primary;
    .bottom-full {
      @extend %block-width-1350;
      grid-row: 2/2;
      grid-column: 1/3;
      color: $white;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      box-sizing: border-box;
      > div {
        display: flex;
        height: 100%;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        > div {
          font-size: $f14;
          > a {
            color: $white;
            font-size: $f14;
            text-decoration: none;
          }
        }
      }
    }
  }
  .mobile-logo {
    display: none;
  }
}

@media screen and (max-width: $screen__l) {
  #footer {
    .footer-top-content {
      .logo {
        width: 40%;
        svg {
          width: 100%;
        }
      }
      .top-right {
        width: 60%;
        ul {
          li {
            a {
              font-size: $f14;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__m) {
  #footer {
    .footer-top-content {
      padding-left: 3rem;
      padding-right: 3rem;
    }
    .footer-bottom-content {
      #footer-bottom.bottom-full {
        padding-left: 3rem;
        padding-right: 3rem;
      }
    }
  }
}
@media screen and (max-width: $screen__s) {
  #footer {
    .footer-top-content {
      flex-direction: column;
      &:before {
        margin: 0 auto;
        right: 0;
        left: 0;
      }
      .logo {
        width: 6.5rem;
        margin: 0 auto;
      }
      .top-right {
        width: 100%;
        ul {
          flex-direction: column;
          padding-left: 0;
          li {
            padding: 2rem 0;
            width: 100%;
            text-align: center;
          }
        }
      }
    }
    .header-logo {
      display: none;
    }

    .mobile-logo {
      display: block;
    }
  }
}
@media screen and (max-width: $screen__xs) {
  #footer {
    #footer-bottom {
      > div {
        flex-direction: column;
        > div {
          &:first-child {
            margin-bottom: 1.5rem;
          }
        }
      }
    }
  }
}

@import "../../../../../styles/branding.scss";
.program-list-container {
  width: 100%;
  display: inline-block;
  height: 100%;
  position: unset;
  .program-false {
    opacity: 0;
    width: 15.5rem;
    position: absolute;
    max-width: unset;
    min-width: unset;
    overflow: hidden;
    right: 0;
    z-index: -1;
  }
  .btn.button.category-options-false,
  .btn.button.category-options-true {
    padding: 0 3.4rem;
  }
  .program-true {
    transition: width 0.5s;
    position: absolute;
    right: 0;
    padding: 0;
    width: 100%;
    top: 3rem;
    background: $white;
    border: none;
    border-top: none;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    -moz-box-shadow: 3px 5px 4px #ccc;
    -webkit-box-shadow: 3px 5px 4px #ccc;
    box-shadow: 3px 5px 4px #ccc;
    .wrapper-inside-program {
      padding: 2rem;
      border: 1px solid $primary;
      border-top: none;
      border-bottom-left-radius: 1.5rem;
      border-bottom-right-radius: 1.5rem;
      -moz-box-shadow: 3px 5px 4px #ccc;
      -webkit-box-shadow: 3px 5px 4px #ccc;
      box-shadow: 3px 5px 4px #ccc;
      .check-fields {
        cursor: pointer;
        .switch-button-text {
          text-transform: uppercase;
          color: $secondary;
        }
      }
    }
    .create-program {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .input-wrapper {
        flex: 1 1 50%;
        margin-right: 10%;
        .input {
          .input-program-input {
            width: 100%;
            padding: 0;
            text-align: center;
            height: 3rem;
            border: none;
            border-bottom: 1px solid #eee;
            border-radius: 0;
            transition: none;
            &::placeholder {
              font-size: $f12;
            }
          }
        }
      }
      .btn-orange {
        &.create-list {
          font-size: $f14;
          flex: 1 1 40%;
          height: 3rem;
          padding: 0 1rem;
          min-width: unset;
          width: 9rem;
        }
        &.btn-white-disabled {
          background: unset;
          color: $light-gray;
          &:hover {
            background: unset;
            color: $light-gray;
          }
        }
      }
    }
  }

  .btn {
    &.category-options-true {
      transition: width 0.5s;
      text-align: left;
      width: 100%;
      position: absolute;
      right: 0;
      -webkit-border-top-left-radius: 2.5rem;
      -webkit-border-top-right-radius: 2.5rem;
      -moz-border-radius-topleft: 2.5rem;
      -moz-border-radius-topright: 2.5rem;
      border-top-left-radius: 1.5rem;
      border-top-right-radius: 1.5rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-color: $primary;
      -moz-box-shadow: 3px 5px 4px #ccc;
      -webkit-box-shadow: 3px 5px 4px #ccc;
      box-shadow: 3px 5px 4px #ccc;
    }
  }
  button {
    &.btn.btn.button {
      &.btn-with-left-icon.btn-white-disabled.plp-button {
        &.category-options-false {
          width: 15.5rem;
          transition: none;
          min-width: unset;
          max-width: unset;
          transition: width 1s;
          position: absolute;
          z-index: 3;
          left: inherit;
          right: 0;
        }
      }
    }
  }
  .button.btn-save.btn-with-left-icon.btn-white-disabled {
    svg {
      top: 20%;
      left: 1.2rem;
      &.fa-arrow-down,
      &.fa-arrow-up {
        left: unset;
        right: 8%;
        display: block;
        top: 30%;
      }
    }

    &:hover {
      &:before {
        background-image: url("../../../../../assets/programsOrangeHover.svg");
        background-size: 100%;
        background-repeat: no-repeat;
        width: 1.5rem;
        height: 1.5rem;
        top: 30%;
        left: 1.2rem;
      }
    }
  }
}
// MEDIA
@media screen and (max-width: $screen__xs) {
  .program-list-container {
    margin-bottom: 2rem;
    width: 100%;
    .btn {
      // &.category-options-false {
      //   width: 100%;
      // }
    }
    .program-true {
      // top: 6.5rem;
      z-index: 9;
    }
    button {
      &.btn.btn.button {
        &.btn-with-left-icon.btn-white-disabled.plp-button {
          text-align: left;
          padding: 0 2rem 0 3.8rem;
          // &.category-options-false {
          //   width: 100%;
          // }
        }
      }
    }
  }
  .save-action {
    .btn-with-left-icon.btn-white-disabled.plp-button {
      padding: 0 2rem 0 3.8rem;
      svg {
        left: 1.5rem;
      }
      &:hover {
        &:before {
          left: 1.5rem;
        }
      }
    }
  }
}

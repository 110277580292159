@import "../../styles/branding.scss";
@import "../../styles/style.scss";
.header-bottom-container {
  border-bottom: 0.1rem solid $primary;
  background-color: rgba(255, 255, 255, 0.9);
  position: relative;
  z-index: 9999;
  opacity: 1;
  .bottom {
    display: flex;
    background-color: transparent;
    position: sticky;
    z-index: 102;
    box-sizing: border-box;
    max-width: $page-max-width;
    margin: 0 auto;

    > div {
      &.logo {
        flex: 1 0 20%;
        margin-left: -1rem;
        box-sizing: border-box;
        svg {
          width: 80%;
          height: 90px;
        }
        .mobile-logo {
          display: none;
        }
      }

      &.nav {
        flex: 1 0 30%;
        margin-left: 5%;
        margin-right: 5%;
        display: flex;
        @extend %nunito-sans-bold;
        justify-content: space-between;
        align-items: center;
        .item {
          display: flex;
          height: 100%;
          align-items: center;
          border-bottom: 0.3rem solid transparent;
          transition: all 0.5s ease-in-out;
          margin-left: 3%;
          box-sizing: border-box;
          font-size: $f14;
          &:hover {
            border-color: $primary;
          }
          a {
            text-decoration: none;
            line-height: 5.1;
          }
          &.active-link {
            border-color: $primary;
          }
        }
      }

      &.search {
        flex: 1 0 20%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        box-sizing: border-box;
        svg {
          height: 2rem;
          width: auto;
        }
        .text {
          padding-right: 1rem;
        }
      }
    }
  }
}
@media screen and (max-width: $screen__l) {
  .header-bottom-container {
    .bottom {
      padding-left: 3rem;
      padding-right: 3rem;
      .nav {
        .item {
          font-size: $f12;
        }
      }
    }
  }
}
@media screen and (max-width: 1300px) {
  .header-bottom-container {
    .bottom {
      > div.nav {
        .item {
          font-size: $f12;
        }
      }
    }
  }
}
@media screen and (max-width: $screen__m) {
  .header-bottom-container {
    .bottom {
      > div.nav {
        flex: 1 0 47%;
      }
      > div.search {
        flex: 1 0 13%;
      }
    }
  }
}
@media screen and (max-width: $screen__s) {
  .header {
    .header-bottom-container {
      padding: 0 3.7rem;
      .bottom {
        padding-left: 0;
        padding-right: 0;
        padding-top: 1.8rem;
        padding-bottom: 1.8rem;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        svg,
        .mobile-logo {
          height: 3.7rem;
          width: auto;
        }

        .mobile-nav {
          display: flex;
          align-items: center;
          grid-row: 1;
          grid-column: 1;
        }

        > div {
          &.nav {
            display: none;
          }
          &.logo {
            grid-row: 1;
            display: flex;
            align-content: center;
            justify-content: center;
            .header-logo {
              display: none;
            }
            .mobile-logo {
              display: inline-block;
            }
          }
          &.search {
            grid-row: 1;
            .text {
              display: none;
            }
            svg {
              height: 3.7rem;
              width: auto;
            }
          }
        }
      }
    }
  }
}

@import "../../../../styles/style.scss";
.account-addresses-container {
  display: flex;
}
@media only screen and (max-width: $screen__s) {
  .account-addresses-container {
    display: flex;
    flex-direction: column;
  }
}

@import "../../../styles/branding.scss";
@import "../../../styles/style.scss";

#sidebar {
  &:empty {
    display: none;
  }
  h3 {
    @extend %nunito-sans-extra-bold;
    letter-spacing: 0.42rem;
    padding-bottom: 3rem;
    &::after {
      display: none;
    }
  }
  h4 {
    margin-bottom: 1.5rem;
    align-items: center;
  }
  .filter-content {
    ul {
      margin-bottom: 3rem;
      > li:only-child {
        margin-bottom: 0;
      }
      li {
        margin-bottom: 1rem;
      }
    }
  }

  .locked-true {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
  }
}

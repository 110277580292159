@import "./branding.scss";

html {
  font-size: 10px;
  height: 100%;
}

body {
  font-family: "Nunito Sans", sans-serif;
  margin: 0;
  height: 100vh;
  font-size: $f12;
  color: $primary;
  cursor: default;
}
/*Font family*/
//Nunito Sans
%nunito-sans-light {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 300;
}
%nunito-sans-regular {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
}
%nunito-sans-italic {
  font-family: "Nunito Sans", sans-serif;
  font-style: italic;
}
%nunito-sans-semi-bold {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
}
%nunito-sans-bold {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
}
%nunito-sans-extra-bold {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 800;
}
//Halant
%halant-light {
  font-family: "Halant", serif;
  font-weight: 300;
}
%halant-regular {
  font-family: "Halant", serif;
  font-weight: 400;
}
%halant-medium {
  font-family: "Halant", serif;
  font-weight: 500;
}
%halant-semi-bold {
  font-family: "Halant", serif;
  font-weight: 600;
}
%halant-bold {
  font-family: "Halant", serif;
  font-weight: 700;
}
%halant-extra-bold {
  font-family: "Halant", serif;
  font-weight: 800;
}
h1 {
  font-size: $f42;
  @extend %halant-medium;
  letter-spacing: 0.42rem;
  line-height: 4.6rem;
  text-transform: uppercase;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: $secondary;
  @media screen and (max-width: $screen__m) {
    font-size: $f36;
    letter-spacing: 0.36rem;
    line-height: 4rem;
  }
  @media screen and (max-width: $screen__xs) {
    font-size: $f24;
    letter-spacing: 0.24rem;
    line-height: 3.2rem;
  }
}

h2 {
  font-size: $f24;
  @extend %halant-medium;
  letter-spacing: 0.24rem;
  line-height: 4.6rem;
  padding-bottom: 2rem;
  text-transform: uppercase;
  color: $primary;
  @media screen and (max-width: $screen__s) {
    font-size: $f21;
    letter-spacing: 0.21rem;
    line-height: 3.2rem;
  }
}

h3 {
  font-size: $f21;
  @extend %nunito-sans-regular;
  letter-spacing: 0.21rem;
  line-height: 3.2rem;
  padding-bottom: 3rem;
  text-transform: uppercase;
  color: $primary;
  position: relative;
  &:after {
    @extend %title-bottom-border;
  }
  @media screen and (max-width: $screen__s) {
    font-size: $f18;
    letter-spacing: 0.18rem;
    line-height: 3.2rem;
    padding-bottom: 2rem;
  }
}

h4 {
  font-size: $f18;
  @extend %halant-semi-bold;
  text-transform: uppercase;
  letter-spacing: 0.18rem;
  line-height: 3.2rem;
}

p {
  font-size: $f16;
  margin: 0;
  line-height: 3rem;
  color: $secondary;
  @extend %nunito-sans-regular;
  @media screen and (max-width: $screen__s) {
    font-size: $f14;
  }
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

a {
  color: $primary;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: $primary;
  }
}

#root {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  // overflow-x: hidden;
  .content {
    flex: 1 0 auto;
    position: relative;
  }
}

ul {
  list-style: none;
}

.page-main-content {
  @extend %page-content-width;
}
%title-bottom-border {
  height: 0.2rem;
  background: $gray-3;
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 8.6rem;
}
%cms-main-title {
  color: $gray-0;
  position: relative;
  @media screen and (max-width: $screen__s) {
    font-size: 3.2rem;
  }
}

%cms-main-title-bottom-border {
  height: 0.2rem;
  background: $gray-3;
  position: absolute;
  content: "";
  left: 0;
  top: 7rem;
  width: 8.6rem;
}
%block-width-1350 {
  margin-left: auto;
  margin-right: auto;
  max-width: $page-max-width;
  @media screen and (max-width: $screen__l) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
%page-content-width {
  margin-left: auto;
  margin-right: auto;
  max-width: $page-max-width;
  padding-top: 5rem;
  @media screen and (max-width: $screen__l) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media screen and (max-width: $screen__l) {
    padding-top: 3rem;
  }
}
%gray-background-with-inner-shadow {
  background: linear-gradient(0deg, $gray-5 1%, $gray-5 2%);
  padding: 10rem 0;
  box-sizing: border-box;
  box-shadow: inset 0 1.8rem 1.8rem -1rem #00000021,
    inset 0 -1.8rem 1.8rem -1rem #00000021;
}
%orange-bottom-border {
  border-bottom: 0.1rem solid $primary;
}

/* Make clicks pass-through 
#nprogress {
  pointer-events: none;
  z-index: 99999;
}

#nprogress .bar {
  background: #29d;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.2rem;
}
*/
/* Fancy blur effect 
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 10rem;
  height: 100%;
  box-shadow: 0 0 1rem #29d, 0 0 0.5rem #29d;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}*/

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
::-webkit-scrollbar {
  width: 0.75rem;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba($color: $primary, $alpha: 0.9);
}
::-webkit-scrollbar-thumb:hover {
  background: rgba($color: $primary, $alpha: 1);
}
.back-to-top-button {
  svg {
    fill: transparent !important;
  }
}
@media screen and (min-width: $screen__l) {
  ::-webkit-scrollbar {
    width: 1.5rem;
  }
}

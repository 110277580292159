@import "../styles/branding.scss";
@import "../styles/style.scss";
.clearfix::before,
.clearfix::after {
  flex-basis: 0;
  order: 1;
}
.header {
  text-transform: uppercase;
  position: sticky;
  top: 3.9rem;
  z-index: 4;

  .mobile-nav {
    display: none;
    svg {
      cursor: pointer;
    }
  }
}
#bottom-header {
  transition: all 0.5s ease-in-out;
}

@media screen and (max-width: $screen__l) {
  .menu {
    &.active {
      width: auto;
    }
  }
}

@media screen and (max-width: $screen__s) {
  .header {
    top: 0;
  }
  .header .menu,
  .header-top-container {
    display: none;
  }
}
@media screen and (min-width: 769px) {
  .mobile-menu {
    display: none;
  }
}

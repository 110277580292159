@import "./../../styles/branding.scss";
.check {
  display: block;
  margin-bottom: 2rem;
  span.label {
    padding-left: 3rem;
  }
}
/*Checkbox button*/
.checkbox-container {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    background-color: $white;
    border: 0.1rem solid $light-gray-2;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 0.5rem;
      top: 0;
      width: 0.4rem;
      height: 0.9rem;
      border: solid $secondary;
      border-width: 0 0.2rem 0.2rem 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
/*Switch button*/
.switch-container {
  position: relative;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  span.label {
    padding-left: 5rem;
  }
  input:checked + .slider {
    background-color: $primary;
  }
  input:focus + .slider {
    box-shadow: 0 0 0.1rem $primary;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(2.6rem);
    -ms-transform: translateX(2.6rem);
    transform: translateX(2.6rem);
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 4.5rem;
    height: 1.5rem;
    background-color: $secondary;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 3.4rem;
    &:before {
      position: absolute;
      content: "";
      height: 2.5rem;
      width: 2.5rem;
      left: -0.2rem;
      bottom: -0.5rem;
      background-color: $gray-5;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
      box-shadow: 0.2rem 0.2rem 0.2rem $shadow-color;
    }
  }
}
/*Radio button*/
.radio-container {
  position: relative;
  margin-bottom: 1.2rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  input:checked ~ .radio:after {
    display: block;
  }
  .radio {
    position: absolute;
    top: 0;
    left: 0;
    height: 2rem;
    width: 2rem;
    background-color: $white;
    border: 0.1rem solid $light-gray;
    border-radius: 50%;
    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 0.5rem;
      left: 0.5rem;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background: $primary;
    }
  }
}

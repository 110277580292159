@import "../styles/branding.scss";
@import "../styles/style.scss";
.product-page-container {
  overflow: visible;
  .breadcrumbs-wrapper {
    margin: 0 auto;
    .breadcrumbs {
      ul {
        padding: 0;
      }
    }
  }
}
@media screen and (min-width: $screen__l) {
  .product-page-container {
    .breadcrumbs-wrapper {
      width: $page-max-width;
    }
  }
}
@media screen and (max-width: $screen__m) {
  .product-page-container {
    padding: 0 3rem;
    overflow: hidden;
  }
}

@import "../../../../../styles/branding.scss";
#toolbar-listing {
  display: flex;
  flex-wrap: wrap;
  margin: 6rem 0 3rem 0;
  button {
    min-width: calc(19% - 0.5rem);
    margin-right: 2rem;
    margin-bottom: 2rem;
    font-weight: normal;

    &:last-child {
      margin-right: 0;
    }
    &.btn-orange {
      font-weight: 800;
    }
  }
}
//MEDIA
@media screen and (max-width: $screen__m) {
  #toolbar-listing {
    width: 100%;
    white-space: nowrap;
    button {
      &.button {
        min-width: 22rem;
        width: calc(100% / 4);
        overflow: hidden;
        display: inline-block;
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  #toolbar-listing {
    button {
      &.button {
        min-width: 22rem;
        width: calc(100% / 2);
        overflow: hidden;
        display: inline-block;
      }
    }
  }
}

@import "../../../../../styles/branding.scss";
@import "../../../../../styles/style.scss";
.usp {
  width: 40rem;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 3rem;
  svg {
    line-height: normal;
    margin: 0 auto;
  }

  .title {
    display: flex;
    justify-content: center;
    h3 {
      padding: 3rem 2rem;
      margin-top: 3rem;
      text-align: center;
      color: #000;
      @extend %nunito-sans-bold;
      &:after {
        width: 100%;
        top: 0;
        background: $primary;
      }
    }
  }
}

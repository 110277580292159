@import "../../../../styles/branding.scss";
@import "../../../../styles/style.scss";
.brand-items-inner-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  transition: opacity 0.5s ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
  &.brand-items-active {
    opacity: 1;
    height: auto;
  }
  .brand-item {
    width: calc(33.3% - 2rem);
    margin-bottom: 6rem;
    .brand-item-top {
      margin-bottom: 3rem;
      position: relative;
      .brand-item-image {
        img {
          width: 100%;
        }
      }
      .brand-item-description {
        text-align: center;
        display: none;
        padding: 6rem 5rem;
        box-sizing: border-box;
        position: absolute;

        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(232, 134, 2, 0.75);
        p {
          color: $white;
        }
      }
    }

    h3.brand-item-title {
      color: $secondary;
      text-align: center;
      &:after {
        display: none;
      }
    }
    &:hover .brand-item-description {
      display: block;
    }
  }
}
@media screen and (max-width: $screen__m) {
  .brand-items-inner-container {
    .brand-item {
      width: calc(50% - 2rem);
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .brand-items-inner-container {
    .brand-item {
      width: calc(100% - 2rem);
    }
  }
}

@import "../../styles/branding";
@import "../../styles/style";

#session-messages-container,
.freightquote-session-messages {
  padding: 0 3rem;
  position: relative;
  .session-messages-inner {
    transition: all 1.5s ease-out;
    margin: 2rem 0;
    box-sizing: border-box;
    z-index: 2;
    div.session {
      box-sizing: border-box;
      border-radius: 2.5rem;
      padding: 1rem;
      display: flex;
      align-content: center;
      font-size: $f16;
      @extend %block-width-1350;
      svg {
        width: 2rem;
        height: 2rem;
        font-size: 2rem;
        padding-right: 1rem;
      }
      &.success {
        background: rgba(100, 206, 113, 0.25%) 0% 0% no-repeat padding-box;
        color: #69b271;
      }
      &.info {
        color: $white;
      }
      &.danger {
        background: rgba(255, 93, 93, 0.25%) 0% 0% no-repeat padding-box;
        color: #c72f2f;
      }
    }
  }
}
#session-messages-container.session-message-home {
  .session-messages-inner {
    transition: all 1.5s ease-out;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    margin: 2rem auto;
    padding: 0 2rem;
    div.session {
      &.success {
        background: rgb(100, 206, 113) 0% 0% no-repeat padding-box;
        color: $white;
      }
      &.danger {
        background: rgb(255, 93, 93) 0% 0% no-repeat padding-box;
        color: $white;
      }
    }
  }
}

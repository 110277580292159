@import "../../../styles/branding.scss";
#registersuccesspage {
  margin-bottom: 14rem;

  h2 {
    padding: 0 0 2rem 0;
    margin-top: 3.1rem;
  }
  hr {
    display: block;
    height: 0.1rem;
    border: 0;

    &.accent {
      background-color: $white;
      border-top: 0.1rem solid $primary;
      margin-top: 4.6rem;
    }
    &.detail {
      border-top: 0.2rem solid $light-gray;

      margin-right: auto;
      margin-left: 0;
      width: 8.5rem;
    }
  }
  .actions {
    margin-top: 3rem;
    a {
      &:nth-of-type(2) {
        margin-left: 3rem;
      }
    }
  }
}
@media screen and (max-width: $screen__s) {
  #registersuccesspage {
    .actions {
      a {
        &:nth-of-type(2) {
          margin-left: 0;
        }
      }
      .btn.button.btn-orange {
        display: block;
        margin: 0 auto;
        width: 100%;
        margin-bottom: 2rem;
      }
    }
  }
}

@import "../../../styles/branding.scss";
@import "../../../styles/style.scss";

#toolbar {
  padding-bottom: 4rem;
  border-bottom: 0.2rem solid $primary;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .filters-control {
    display: flex;
    align-items: center;
    flex: 1 0 50%;
    .show-hide-button {
      flex: 1 0 35%;
      margin-right: 1rem;
      .switch-container {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        .label {
          text-transform: uppercase;
          color: $primary;
          font-size: $f16;
          @extend %nunito-sans-extra-bold;
          letter-spacing: 0.32rem;
          line-height: 1.7rem;
          .switch-button-text {
            display: none;
          }
          .text-hidden-filters {
            display: none;
          }
          .text-visible-filters {
            display: block;
          }
        }
      }
    }
    .show-hide-button.show-true {
      .switch-container {
        .label {
          .text-hidden-filters {
            display: block;
          }
          .text-visible-filters {
            display: none;
          }
        }
      }
    }
    .product-item-number {
      flex: 1 0 65%;
      color: $primary;
      line-height: 1.7rem;
      text-transform: uppercase;
    }
  }
}

.sort-control {
  display: flex;
  align-items: center;
  flex: 1 0 50%;
  .sort-options {
    flex: 1 0 50%;
    display: flex;
    justify-content: flex-end;
    .sort-options-content {
      display: flex;
      align-items: center;
      .sorter-label {
        padding: 0 1.5rem;
        font-size: $f16;
        box-sizing: border-box;
      }
      .select-wrapper {
        width: 20rem;
        margin-bottom: 0;
        .selector {
          width: 20rem;
          border: 0.1rem solid $secondary;
          padding: 0;
          box-sizing: border-box;
          cursor: pointer;
          input[type="text"] {
            padding: 2.5rem;
            cursor: pointer;
          }
          .selections {
            .option {
              padding: 1rem 2.5rem;
            }
          }
          &:after {
            content: "▼";
            position: absolute;
            right: 2.5rem;
            top: 1.5rem;
            color: $secondary;
            z-index: 2;
            font-size: $f14;
            text-align: center;
            cursor: pointer;
          }
          input {
            width: 100%;
            padding: 0;
            cursor: pointer;
          }
          input {
            &::placeholder {
              color: $secondary;
            }
          }
        }
        .selector.open {
          input {
            &::placeholder {
              color: $primary;
            }
          }
          &:after {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .arrow-down {
    width: auto;
    display: flex;
    justify-content: flex-start;
    margin: 0 1.5rem;
    transition: all 0.2s ease-in-out;
    svg {
      transition: all 0.2s ease-in-out;
      transform: rotate(90deg);
      width: 2rem;
      g {
        path,
        line {
          stroke: $secondary;
        }
      }
    }
    &.arrow-up {
      svg {
        transition: all 0.2s ease-in-out;
        transform: rotate(-90deg);
      }
    }
  }
  .save-all {
    flex: 0 0 auto;
    display: flex;
    justify-content: flex-end;
    height: 100%;
  }
}

@media screen and (max-width: $screen__l) {
  #toolbar {
    .filters-control {
      flex: 1 0 40%;
      .show-hide-button {
        flex: 1 0 50%;
      }
      .product-item-number {
        flex: 1 0 50%;
      }
    }
    .sort-control {
      flex: 1 0 60%;
    }
  }
}
@media screen and (max-width: $screen__m) {
  #toolbar {
    .filters-control {
      flex: 1 0 30%;
    }
    .sort-control {
      flex: 1 0 70%;
    }
  }
}
@media screen and (max-width: $screen__s) {
  #toolbar {
    flex-direction: column-reverse;
    .filters-control {
      flex: 1 0 100%;
    }
    .sort-control {
      flex: 1 0 100%;
      margin-bottom: 3rem;
      .sort-options {
        justify-content: flex-start;
        .sorter-label {
          display: none;
        }
      }
      .arrow-down {
        display: none;
      }
    }
  }
}
@media screen and (max-width: $screen__s) {
  #toolbar {
    .sort-control {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .toolbar-content {
        width: calc(1 / 2 * 100% - (1 - 1 / 2) * 2%);
        flex: none !important;
      }
      .sort-options {
        .sort-options-content {
          width: 100%;
          .select2 {
            width: 100%;
          }
        }
      }
    }
    .filters-control {
      .show-hide-button,
      .show-hide-button.show-true {
        .switch-container {
          input {
            display: none;
          }
          .slider {
            display: none;
          }
          .label {
            margin: 0 auto;
            padding-left: 0;
            .switch-button-text {
              display: block;
              text-decoration: underline;
              font-size: $f12;
              letter-spacing: 0.24rem;
            }
            .text-hidden-filters {
              display: none;
            }
            .text-visible-filters {
              display: none;
            }
          }
        }
      }
      .product-item-number {
        font-size: $f12;
        text-align: center;
      }
    }
    button.button {
      font-size: $f12;
      letter-spacing: 0.24rem;
      min-width: 100%;
      padding: 0 2rem 0 2rem;
      width: 100%;
      svg {
        display: none;
      }
      &:before {
        display: none;
      }
    }
  }
}

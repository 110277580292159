@import "../../../styles/branding.scss";
@import "../../../styles/style.scss";

#form-container-registration-form {
  @extend %page-content-width;
  #registration-form {
    .grecaptcha-badge {
      display: none !important; // Has to be important due to inline css
    }
    .legend {
      h1 {
        position: relative;
        margin-bottom: 3rem;
        &:after {
          @extend %title-bottom-border;
        }
      }
      h3 {
        &:after {
          display: none;
        }
      }
    }
    h4 {
      padding-left: 2rem;
      border-left: 0.2rem solid $primary;
    }
    .section-title {
      display: flex;
      align-items: center;
      margin-bottom: 3rem;
      margin-top: 5rem;
      .number {
        background-color: $primary;
        color: $white;
        border-radius: 2.5rem;
        font-size: $f16;
        box-sizing: border-box;
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 2rem;
      }
    }
    .inputs {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: baseline;
      border-bottom: 0.1 solid $primary;
      justify-content: space-between;
      margin-bottom: 3rem;
      .input-fields {
        display: flex;
        flex-direction: column;
        width: calc(1 / 2 * 100% - (1 - 1 / 2) * 3%);
        .input-wrapper {
          margin-bottom: 4.5rem;
          max-width: 100%;
          width: 100%;
          position: relative;
          &.partial {
            margin-bottom: 1.5rem;
            &.ups {
              margin-bottom: 4.5rem;
            }
          }
          .input {
            width: 100%;
            input {
              width: 100%;
            }
          }
        }
        .officeNumberMask {
          position: relative;
          overflow: hidden;
          .magicBox {
            position: absolute;
            top: 0.5rem;
            left: 6rem;
            width: 90%;
            height: 4rem;
            border: none;
            z-index: 3;
            border-radius: 40%;
            &:focus {
              outline: none;
            }
            &::placeholder {
              color: #bfbfbf;
              text-transform: uppercase;
            }
          }
        }
      }

      .small-inputs {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 100%;
        margin-bottom: 0;
        width: 100%;
        .small {
          width: calc(1 / 2 * 100% - (1 - 1 / 2) * 3%);
          .selector {
            max-width: 100%;
            width: 100%;
            input {
              max-width: 100%;
              width: 100%;
            }
          }
          .input {
            width: 100%;
            max-width: 100%;
            input {
              max-width: 100%;
              width: 100%;
            }
          }
        }
      }
      .select-wrapper {
        &.small {
          margin-bottom: 4.5rem;
          max-width: 100%;
          width: calc(1 / 2 * 100% - (1 - 1 / 2) * 3%);
          position: relative;
          .selector {
            width: 100%;
            input {
              width: 100%;
            }
          }
          &.preffered {
            width: 100%;
          }
        }
      }
      .privacy-policy {
        width: calc(1 / 2 * 100% - (1 - 1 / 2) * 3%);
        label {
          float: left;
        }
      }
    }
    .section-control {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3rem;
      &.register {
        button.btn-orange.previous {
          position: unset;
        }
      }
    }
    .section-item {
      transition: opacity 500ms linear, transform 500ms ease-in-out;
      &.slide-enter {
        display: block;
        opacity: 0;
        transform: scale(0.97) translateY(0);
        z-index: 1;
      }
      &.slide-enter.slide-enter-active {
        opacity: 1;
        transform: scale(1) translateY(0);
        transition: opacity 500ms linear 500ms,
          transform 500ms ease-in-out 500ms;
      }
      &.slide-exit-done {
        opacity: 0;
        max-height: 0;
      }
      /* slide exit */
      &.slide-exit {
        opacity: 1;
        transform: scale(1) translateY(0);
      }
      &.slide-exit.slide-exit-active {
        opacity: 0;
        transform: scale(0.97) translateY(0);
      }
      &.slide-exit-done {
        opacity: 0;
        visibility: hidden;
        transform: scale() translateY(0);
      }
      &.slide-enter-done {
        display: block;
        transform: scale(1) translateY(0);
        transition: opacity 500ms linear 500ms,
          transform 500ms ease-in-out 500ms;
      }
      &.first {
        &.slide-exit-active,
        &.slide-exit-done {
          display: none;
        }
      }
    }
    .first {
      .inputs {
        max-height: 100%;
        transform: scale(1) translateY(0);
        transition: opacity 1500ms linear 1500ms,
          transform 1500ms ease-in-out 1500ms;
      }
    }
    .second {
      display: none;

      .inputs {
        max-height: 100%;
        transform: scale(1) translateY(0);
        transition: opacity 1500ms linear 1500ms,
          transform 1500ms ease-in-out 1500ms;
        .input-fields {
          .select2.small {
            .react-select-container {
              margin-bottom: 4.5rem;
              .react-select__control {
                height: 5rem;
                border: 0.1rem solid $gray-3;
                transition: all 0.1s ease-in-out;
                &.react-select__control--menu-is-open {
                  transition: all 0.1s ease-in-out;
                }
              }
              .react-select__control--is-focused {
                border: 0.1rem solid $primary;
              }
              .react-select__control--is-disabled {
                color: rgb(84, 84, 84);
                cursor: default;
                background-color: rgb(235, 235, 228);
              }
              .react-select__menu {
                transition: all 0.3s ease-in-out;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: $screen__m) {
  #form-container-registration-form {
    max-width: 100%;
    padding: 3.6rem;
    #registration-form {
      .inputs {
        .input-fields {
          width: 100%;
          .input-wrapper {
            width: 100%;
            max-width: 100%;
          }
          .input {
            width: 100%;
            input {
              width: 100%;
            }
          }
          .select-wrapper {
            width: 100%;
            max-width: none;
            .selector {
              width: 100%;
            }
          }
          .small-inputs {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            max-width: 100%;
            width: 100%;
            .small {
              width: 100%;
              .selector {
                max-width: 100%;
                input {
                  max-width: 100%;
                }
              }
              .input {
                max-width: 100%;
                input {
                  max-width: 100%;
                }
              }
            }
          }
          .select-wrapper {
            &.small {
              width: 100%;
            }
          }
        }
        .first {
          .inputs {
            max-height: none;
          }
        }
        .second {
          .inputs {
            max-height: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $screen__m) {
  #form-container-registration-form {
    #registration-form {
      .section-control {
        justify-content: center;
        button.previous,
        button.btn-orange {
          display: inline-block;
          width: 49%;
          margin: 0 1%;
          min-width: unset;
        }
      }
    }
  }
}
@media only screen and (max-width: $screen__xs) {
  #form-container-registration-form {
    #registration-form {
      .inputs {
        .input-fields {
          .input-wrapper {
            .input {
              input {
                padding-left: 5.5rem;
                padding-right: 8rem;
              }
              .required {
                right: 2rem;
              }
            }
          }
          .officeNumberMask {
            .magicBox {
              width: 51.5%;
              overflow: hidden;
              left: 5.5rem;
              border-radius: 0;
              top: 3px;
              &.mobile {
                width: auto;
              }
            }
          }
        }
      }
    }
  }
}

@import "../../../styles/branding.scss";
@import "../../../styles/style.scss";
.menu {
  position: absolute;
  top: -50rem;
  background-color: $white;
  z-index: 99;
  transition: all 0.5s ease-in-out;
  border: 0.1rem solid $primary;
  border-top: none;
  box-shadow: 0 0.6rem 0.6rem 0 rgba(0, 0, 0, 0.33);
  display: flex;
  flex-direction: row;
  left: 50%;
  transform: translateX(-50%);
  &.active {
    top: 9.4rem;
  }
  &.Brands {
    top: -70rem;
    &.active {
      top: 9.4rem;
    }
  }
  .tabs {
    font-size: $f14;
    @extend %nunito-sans-bold;
    letter-spacing: 0.14rem;
    color: $secondary;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: $gray-5;
    box-shadow: 0.6rem 0 0.6rem 0 rgba(0, 0, 0, 0.1);
    width: 20rem;

    .tab {
      border-bottom: 0.1rem solid $gray-3;
      width: 20rem;
      padding: 3rem 1rem 3rem 2rem;
      box-sizing: border-box;
      line-height: 1.9rem;
      transition: all 0.2s ease-in-out;
      p {
        font-size: $f14;
        color: $gray-2;
        margin: 0;
        @extend %nunito-sans-italic;
      }
      &.active {
        background-color: $primary;
        color: $white;
        cursor: pointer;
      }
      &:last-of-type {
        padding: 2rem 1rem 2rem 2rem;
      }
    }
  }
  .middle {
    padding: 3rem;
    width: 40rem;
    .items {
      display: flex;
      flex-wrap: wrap;
      .link {
        width: 20rem;
        overflow: hidden;
        height: 3rem;
        font-size: $f12;
        letter-spacing: 0.12rem;
        text-decoration: none;
        display: inline-block;
        color: $secondary;
        &:hover {
          color: $primary;
        }
      }
    }
    .title {
      color: $gray-0;
      margin-bottom: 1.5rem;
      p {
        font-size: $f14;
        @extend %nunito-sans-bold;
        letter-spacing: 0.14rem;
      }
    }
  }
  &.long {
    .middle {
      width: 60rem;
    }

    .right {
      min-width: none;
    }
  }
  .right {
    padding: 3rem;
    .image {
      img {
        width: 100%;
      }
      margin-bottom: 2rem;
    }
    .additional {
      text-align: left;
      .link {
        font-size: $f12;
        color: $secondary;
        padding-bottom: 0.5rem;
        box-sizing: border-box;
        text-decoration: none;
        width: 1rem;
        border-bottom: 0.2rem solid $primary;
      }
    }
  }
}
.menu.Products {
  max-width: 96rem;
  .image {
    width: 43rem;
  }
}
.menu.Brands {
  max-width: 110rem;
  left: 46%;
  .middle {
    width: 30rem;
    .items {
      -webkit-column-count: 2; /* Chrome, Safari, Opera */
      -moz-column-count: 2; /* Firefox */
      column-count: 2;
      display: flex;
      flex-direction: row;
      a.link {
        height: 2.5rem;
        flex: 1 0 50%;
      }
    }
  }
  .image {
    width: 43rem;
  }
}
@media screen and (max-width: $screen__m) {
  .menu {
    top: -100rem;
  }
  .menu.Products {
    .middle {
      width: 100%;
      .items {
        -webkit-column-count: 2; /* Chrome, Safari, Opera */
        -moz-column-count: 2; /* Firefox */
        column-count: 2;
        display: block;
      }
    }
    .right {
      .image {
        width: 40rem;
      }
    }
  }
  .menu.Brands {
    max-width: 100%;
    left: 50%;
    .right {
      .image {
        width: 30rem;
      }
    }
    .tabs {
      width: 15rem;
      .tab {
        width: 15rem;
        padding-left: 1rem;
      }
    }
  }
}
@media screen and (max-width: 990px) {
  .menu.Products {
    .middle {
      .items {
        column-count: auto;
        overflow-y: auto;
        height: 30rem;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .menu.Brands {
    .middle {
      width: 100%;
      .items {
        -webkit-column-count: auto; /* Chrome, Safari, Opera */
        -moz-column-count: auto; /* Firefox */
        column-count: auto;
      }
    }
  }
}

@import "../../../../styles/branding.scss";
@import "../../../../styles/style.scss";

.selected-filter {
  margin-bottom: 3rem;
  h4 {
    &:after {
      display: none;
    }
  }
  p {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }
  span {
    color: $secondary;
    text-transform: uppercase;
    font-size: $f12;
  }
  .clear-selected {
    text-transform: uppercase;
    @extend %nunito-sans-italic;
    margin-top: 2rem;
    text-decoration: underline;
    color: $primary;
    font-size: $f16;
    cursor: pointer;
    border: none;
    background: transparent;
    margin: 0;
    padding: 0;
    &:focus {
      outline: none;
    }
  }
  .close-btn {
    margin-right: 0.4rem;
  }
  .filter-item {
    .category {
      margin-right: 0.5rem;
      @extend %nunito-sans-bold;
    }
  }
}

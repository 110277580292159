@import "../../../../../../styles/branding";
@import "../../../../../../styles/style";
.media {
  display: flex;
}
.main-block-heading {
  tr {
    th {
      @extend %halant-semi-bold;
      font-size: 1.4rem;
      text-transform: uppercase;
      letter-spacing: 0.14rem;
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .main-block-heading {
    tr {
      th {
        display: none;
      }
    }
  }
}

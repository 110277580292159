@import "./../../styles/branding.scss";

.map {
  margin-top: 10rem;
  img {
    width: 100%;
  }
}
.map-image {
  border-top: 0.3rem solid $primary;
  max-height: 55rem;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
@media only screen and (max-width: 487px) {
  .map {
    display: none;
  }
}

@import "./../../styles/branding.scss";

$dur: 0.3s;

.input {
  display: inline-block;
  position: relative;
  font-size: 1.2rem;
  z-index: 0;
  input,
  textarea {
    outline: none;
    border: 0.1rem solid $gray-3;
    height: 5rem;
    width: 66rem;
    border-radius: 2.5rem;
    box-sizing: border-box;
    padding-left: 6.5rem;
    padding-right: 10rem;
    transition: all $dur ease-in-out;

    &:not([value=""]),
    &:focus {
      border-color: $primary;
      color: #000;

      & ~ .icon {
        fill: $primary;
      }
      & ~ .required {
        color: $primary;
      }
    }
    &::placeholder {
      text-transform: uppercase;
      color: $gray-3;
    }
  }
  .icon {
    text-transform: uppercase;
    position: absolute;
    top: 0;
    left: 2.5rem;
    display: flex;
    height: 100%;
    fill: $gray-3;
    align-items: center;
    svg {
      fill: inherit;
      path {
        transition: all $dur ease-in-out;
        fill: inherit;
      }
      width: 2.5rem;
      text-align: left;
    }
  }
  .required {
    transition: all $dur ease-in-out;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    right: 3.5rem;
    color: $gray-3;
    display: flex;
    height: 100%;
    align-items: center;
  }
  &.input-validation-error {
    input {
      border: 0.1rem solid #cb1e46;
    }
  }
}

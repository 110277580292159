@import "../../styles/branding.scss";
@import "../../styles/style.scss";
.contentheader {
  h3,
  h1 {
    text-align: center;
  }
  h3 {
    &::after {
      right: 0;
      margin: 0 auto;
    }
  }
}

@import "../../../styles/branding.scss";
@import "../../../styles/style.scss";

.page-1 {
  .breadcrumbs {
    display: none;
  }
}
.error-page {
  h1 {
    position: relative;
    &:after {
      @extend %title-bottom-border;
    }
  }
  h2 {
    text-transform: uppercase;
  }
  .error-page-img {
    img {
      width: 100%;
    }
  }
  .error-page-inner-content {
    @extend %page-content-width;
    .error-page-text {
      padding-top: 3rem;
      margin-bottom: 3rem;
      @extend %orange-bottom-border;
      h3 {
        padding-bottom: 2rem;
        &:after {
          display: none;
        }
      }
      p {
        color: $gray-0;
        margin: 0;
        line-height: 3.2rem;
      }
      a {
        text-transform: uppercase;
        text-decoration: underline;
        color: $gray-0;
      }
      .what-can-you-do {
        padding: 5rem 0;
      }
    }
  }
}
.error-page-buttons {
  @extend %block-width-1350;
  padding-bottom: 15rem;
  button {
    margin-right: 3rem;
  }
}
/*Media Queries*/
@media screen and (max-width: $screen__l) {
  .error-page-buttons {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
//Tablet
@media screen and (max-width: $screen__s) {
  .error-page-buttons {
    padding-bottom: 11.4rem;
    .btn.button.btn-orange {
      display: block;
      margin: 0 auto;
      width: 100%;
      margin-bottom: 2rem;
    }
  }
}

@import "../../../../styles/branding.scss";
@import "../../../../styles/style.scss";

.home-our-products-block {
  position: relative;
  &:before {
    content: " ";
    width: 100%;
    height: 95rem;
    @extend %gray-background-with-inner-shadow;
    position: absolute;
    z-index: -1;
  }
  .home-our-products-block-inner {
    @extend %block-width-1350;
    padding-top: 10rem;
    padding-bottom: 10rem;
    text-align: center;
    box-sizing: border-box;
    .home-our-products-top {
      padding: 0 11.5rem;
      .contentheader {
        h1 {
          padding-bottom: 3rem;
        }
      }
      p {
        padding: 0 11.5rem 4rem 11.5rem;
      }
    }

    .home-category-block {
      display: flex;
      flex-direction: column;
      .home-category-item {
        background: $white;
        h2 {
          font-size: $f21;
          padding: 2rem 0;
        }
      }
      .home-category-top-row {
        margin-bottom: 3rem;
        display: flex;
        justify-content: space-between;
        overflow: visible;
        height: 100%;
        align-items: center;
        min-height: 45rem;
        .home-category-item {
          width: calc(1 / 3 * 100% - (1 - 1 / 3) * 3%);
          &:hover {
            overflow: visible;
            transition: all 0.5s ease-in-out;
            height: 100%;
            background: $white;
          }
          .home-category-item-inner {
            background: $white;
            transition: all 0.5s ease-in-out;
            outline: 0.2rem solid $black;
            &:hover {
              margin: -2rem;
              transform: scale(1);
              overflow: auto;
              padding: 1.5rem;
              transition: all 0.6s ease-in-out;
              outline: 0.2rem solid $black;
            }
          }
        }
      }
      .home-category-bottom-row {
        margin-bottom: 9rem;
        min-height: 40rem;
        display: flex;
        justify-content: space-between;
        .home-category-item {
          width: calc(1 / 4 * 100% - (1 - 1 / 4) * 2%);
          .home-category-item-inner {
            background: $white;
            transition: all 0.5s ease-in-out;
            outline: 0.2rem solid $black;
            &:hover {
              margin: -2rem;
              transform: scale(1);
              padding: 1rem;
              transition: all 0.5s ease-in-out;
              outline: 0.2rem solid $black;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__l) {
  .home-our-products-block {
    .home-our-products-block-inner {
      padding: 10rem 3rem;
    }
  }
}
@media screen and (max-width: $screen__m) {
  .home-our-products-block {
    .home-our-products-block-inner {
      padding: 6rem 3rem 8rem 3rem;
    }
  }
}
@media screen and (max-width: $screen__m) and (min-width: 769px) {
  .home-our-products-block {
    .home-our-products-block-inner {
      .home-category-block {
        .home-category-bottom-row {
          margin-bottom: 0;
          min-height: 35rem;
        }
      }
    }
  }
}
@media screen and (max-width: $screen__s) {
  .home-our-products-block {
    &:before {
      height: 160rem;
    }
    .home-our-products-block-inner {
      .home-our-products-top {
        padding-right: 3rem;
        padding-left: 3rem;
        p {
          padding-right: 0;
          padding-left: 0;
        }
      }
      .home-category-block {
        .home-category-item {
          h2 {
            font-size: $f16;
          }
          &:hover {
            h2 {
              font-size: $f16;
            }
          }
        }
        .home-category-top-row {
          flex-wrap: wrap;
          .home-category-item {
            &:first-child {
              width: 100%;
            }
          }
          .home-category-item {
            width: calc(50% - 2.5rem);
            &:first-child,
            &:nth-child(2),
            &:nth-child(3) {
              margin-bottom: 3rem;
            }
          }
        }
        .home-category-bottom-row {
          flex-wrap: wrap;
          .home-category-item {
            width: calc(50% - 2.5rem);
            &:first-child,
            &:nth-child(2) {
              margin-bottom: 3rem;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .home-our-products-block {
    &:before {
      height: 145rem;
    }
    .home-our-products-block-inner {
      .home-category-block {
        .home-category-top-row {
          .home-category-item {
            width: calc(50% - 1.5rem);
          }
        }
        .home-category-bottom-row {
          .home-category-item {
            width: calc(50% - 1.5rem);
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__xxs) {
  .home-our-products-block {
    &:before {
      height: 137rem;
    }
  }
}

@import "../../styles/branding.scss";
@import "../../styles/style.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;

  right: 0;
  background: rgba(0, 0, 0, 0.7);
  align-items: center;
  justify-content: center;
  z-index: -999;
  opacity: 0;
  display: none;
  &.fade-enter {
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    z-index: 5;
    display: flex;
  }
  &.fade-enter-done {
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
    z-index: 5;
    display: flex;
  }
  &.fade-enter-active {
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
    z-index: 5;
    display: flex;
  }
  &.fade-exit {
    transition: opacity 0.5s ease-in-out;
    z-index: 5;
    display: flex;
  }
  &.fade-exit-done {
    transition: opacity 0.5s ease-in-out;
    position: absolute;
    z-index: -1;
    display: flex;
  }
  &.fade-exit-active {
    transition: opacity 0.5s ease-in-out;
    z-index: 5;
    display: flex;
  }

  .modal-container {
    position: fixed;
    background: $white;
    padding: 0;
    border: 0.2rem solid $primary;
    max-width: 60rem;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .modal-main {
      .modal-heading {
        padding: 1rem 3rem;
        background: $primary;
        h2 {
          color: $white;
          font-size: 2.8rem;
          padding: 0;
          text-align: center;
        }
      }
      // used when customer token is present
      .modal-success-message {
        padding: 6rem 2rem;
        text-align: center;
        font-size: $f21;
      }
      .modal-inner {
        padding: 2rem 3rem;
        .modal-subHeading {
          text-align: center;
          h3 {
            padding-bottom: 2rem;
            &:after {
              left: 0;
              right: 0;
              margin: 0 auto;
            }
          }
        }
        .logged-in-message {
          padding: 2rem 3rem;
          font-size: $f16;
          color: $secondary;
        }
      }
      .modal-footer {
        background: $gray-5;
        border-top: 0.1rem solid $primary;
        padding: 1rem 3rem;
        text-align: center;
        h3 {
          font-size: $f16;
          padding: 0;
          margin: 1rem 0;
          &::after {
            content: none;
          }
          a {
            margin-left: 1rem;
          }
        }
      }
    }
  }
}

.display-block {
  display: block;
}
.display-none {
  display: none;
}
// MEDIA
@media screen and (min-width: $screen__s) {
  .modal-container {
    width: 50rem;
  }
}
@media screen and (max-width: $screen__s) {
  .modal {
    .modal-container {
      width: 85%;
      max-width: 100%;
      top: 10rem;
      left: auto;
      transform: none;
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .modal {
    .modal-container {
      ::-webkit-scrollbar {
        width: 1rem;
      }
      .modal-main {
        .modal-heading {
          padding: 1rem;
          h2 {
            font-size: 2rem;
            line-height: normal;
          }
        }
        .modal-inner {
          padding: 1rem;
          .modal-subHeading {
            h3 {
              font-size: $f14;
              padding-bottom: 1rem;
            }
          }
          #login-form {
            .input-wrapper {
              margin: 1.7rem 0;
            }
            .login-btn {
              .forgot-pw {
                margin-top: 1rem;
                font-size: $f12;
              }
            }
          }
        }
        .modal-footer {
          padding: 1rem;
          .footerCTA {
            h3 {
              margin: 0;
              font-size: $f12;
              letter-spacing: normal;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}

@import "../../../../../../styles/branding.scss";
.remove-all {
  position: absolute;
  bottom: -6rem;
  display: inline-block;
  width: 100%;
  text-align: center;
  .btn-black {
    &:hover {
      background-color: transparent;
      border: 1px solid #000;
      color: #000;
    }
  }
}

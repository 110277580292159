@import "../../../styles/branding.scss";
@import "../../../styles/style.scss";

.plp-container {
  #listing {
    .listing-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .listing-no-products {
        width: 100%;
        margin-bottom: 3rem;
      }
      .product-item-content {
        transition: max-width 0.2s ease-in-out;
        .react-select__control {
          width: 15.8rem;
          height: 3.1rem;
          min-height: 100%;
          input {
            padding: 0 2rem 0 4.8rem;
            height: 100%;
            width: 16rem;
            font-size: 1.4rem;
            cursor: pointer;
          }
          .react-select__value-container {
            padding-left: 3.3rem;
            line-height: auto;
            height: auto;
            top: auto;
            transform: none;
          }
          .react-select__indicators {
            height: 100%;
          }
          .react-select__placeholder {
            transform: none;
          }
          .react-select__menu {
            max-height: 25rem;
          }
        }
      }
    }
    .list-more-products-btn {
      margin-top: 6rem;
      svg {
        padding: 0 1rem;
        transition: animation 0.5 ease-in-out;
        animation: spin 2s linear infinite;
        opacity: 1;
      }
      @keyframes spin {
        0% {
          transform: rotateZ(0);
        }
        100% {
          transform: rotateZ(360deg);
        }
      }
    }
    .list-more-products-btn.remove-icon {
      svg {
        opacity: 0;
      }
    }
  }

  // SIDEBAR HIDDEN
  &.sidebar-hidden {
    #listing {
      // padding: 0;
      .listing-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        &::after {
          content: "";
          flex-grow: 0;
          width: calc(1 / 4 * 100% - (1 - 1 / 4) * 2.22%);
        }
        .listing-no-products {
          width: 100%;
          margin-bottom: 3rem;
        }
        .product-item-content {
          width: calc(1 / 4 * 100% - (1 - 1 / 4) * 2.22%);
          margin: 0;
          transition: max-width 0.2s ease-in-out;
          @media screen and (max-width: $screen__s) {
            max-width: 100%;
            width: 100%;
            .product-item-buttons {
              .save-action {
                width: 100%;
                .btn-save.plp-button {
                  text-align: left;
                }
              }
              .select-wrapper {
                width: 100%;
                .selector {
                  width: 100%;
                }
              }
            }
            .select2 {
              width: 100%;
              .react-select__control {
                width: 100%;
              }
            }
          }
        }
      }
    }
    #sidebar {
      opacity: 0;
    }
  }
}

// SIDEBAR VISIBLE
.plp-container.listing-width100 {
  &.sidebar-visible {
    #listing {
      .listing-content {
        &::after {
          content: "";
          flex-grow: 0;
          width: calc(1 / 3 * 100% - (1 - 1 / 3) * 2.22%);
        }
        .product-item-content {
          width: calc(1 / 3 * 100% - (1 - 1 / 3) * 2.22%);
          flex-grow: 0;
        }
      }
    }
  }
}

// MEDIA
@media screen and (max-width: 1366px) {
  .plp-container {
    &.listing-width100 {
      &.sidebar-visible {
        #listing {
          .listing-content {
            &::after {
              content: "";
              flex-grow: 0;
              width: calc(1 / 2 * 100% - (1 - 1 / 2) * 2.22%);
            }
            .product-item-content {
              flex-grow: 0;
              width: calc(1 / 2 * 100% - (1 - 1 / 2) * 2.22%);
            }
          }
        }
      }
      &.sidebar-hidden {
        #listing {
          .listing-content {
            &::after {
              content: "";
              flex-grow: 0;
              width: calc(1 / 3 * 100% - (1 - 1 / 3) * 2.22%);
            }
            .product-item-content {
              flex-grow: 0;
              width: calc(1 / 3 * 100% - (1 - 1 / 3) * 2.22%);
              .save-action,
              .select2 {
                flex: 0 0 48%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen__m) {
  #listing.listing {
    flex-direction: column;
  }
}
@media screen and (max-width: $screen__s) {
  .plp-container {
    &.listing-width100 {
      &.sidebar-visible {
        #listing {
          .listing-content {
            &::after {
              content: "";
              flex-grow: 0;
              width: calc(1 / 2 * 100% - (1 - 1 / 2) * 2.22%);
            }
            .product-item-content {
              flex-grow: 0;
              width: calc(1 / 2 * 100% - (1 - 1 / 2) * 2.22%);
            }
          }
          &.slide-exit-done {
            padding-left: 0;
          }
        }
      }
      &.sidebar-hidden {
        #listing {
          .listing-content {
            &::after {
              content: "";
              flex-grow: 0;
              width: calc(1 / 2 * 100% - (1 - 1 / 2) * 2.22%);
            }
            .product-item-content {
              flex-grow: 0;
              width: calc(1 / 2 * 100% - (1 - 1 / 2) * 2.22%);
              .save-action,
              .select2 {
                flex: 0 0 48%;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 660px) {
  .plp-container {
    &.listing-width100 {
      &.sidebar-visible {
        #listing {
          .listing-content {
            .product-item-content {
              flex-grow: 0;
              width: 100%;
              margin: 0 auto;
            }
          }
        }
      }
      &.sidebar-hidden {
        #listing {
          .listing-content {
            .product-item-content {
              flex-grow: 0;
              width: 100%;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}

@import "../../../styles/branding.scss";
@import "../../../styles/style.scss";
#forgotpasswordpage {
  h1 {
    position: relative;
    &:after {
      @extend %title-bottom-border;
    }
  }
  .content-with-bottom-border {
    @extend %orange-bottom-border;
    p {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
    .input-wrapper {
      position: relative;
      width: 66rem;
      margin-bottom: 4.5rem;
    }

    margin-bottom: 3rem;
  }
  button.btn.button {
    min-width: 31rem;
  }
  padding-bottom: 15rem;
  box-sizing: border-box;
}
@media screen and (max-width: $screen__s) {
  #forgotpasswordpage {
    .content-with-bottom-border {
      .input-wrapper {
        width: 100%;
        .input {
          width: 100%;
          input {
            width: 100%;
          }
        }
      }
    }
    padding-bottom: 8.5rem;
  }
}
@media screen and (max-width: $screen__xs) {
  #forgotpasswordpage {
    button.btn.button {
      min-width: 100%;
    }
  }
}

@import "../../../styles/branding.scss";
@import "../../../styles/style.scss";

#productfreightinfo {
  margin-bottom: 3.5rem;
  h1,
  h2,
  h3,
  h4 {
    text-align: center;
  }
  h3 {
    &:after {
      transform: translate(-50%, -50%);
      left: 50%;
      right: 50%;
    }
  }
  .freight-info-label {
    padding: 1.5rem;
    line-height: 1.5;
    border-top: 0.1rem solid $primary;
  }
  .freight-columns {
    display: flex;
    flex-direction: row;
    border-top: 0.1rem solid $primary;
    border-bottom: 0.1rem solid $primary;
    .columns {
      flex: 1 1 50%;
      padding: 1.5rem 0 1.5rem 2.5rem;
      p {
        @extend %halant-medium;
        font-size: $f14;
        color: $primary;
        margin: 1rem 0;
        span {
          @extend %nunito-sans-regular;
          color: $secondary;
        }
      }
    }
    .left-column {
      border-right: 0.1rem solid $primary;
    }
  }
  .fill-field {
    text-align: center;
    margin: 2rem 0;
  }
  .input-fields {
    display: flex;
    .input-btn {
      display: flex;
      justify-content: center;
      .input-wrapper {
        margin-right: 3rem;
        &:first-child {
          flex: 0 0 20%;
        }
        .input {
          input {
            width: 100%;
            padding: 1rem;
          }
        }
        &:last-of-type {
          margin-right: 0;
          .input {
            input {
              min-width: 200px;
            }
          }
        }
      }
    }
    button {
      svg {
        padding: 0 1rem;
        transition: animation 0.5 ease-in-out;
        animation: spin 2s linear infinite;
        opacity: 1;
        position: relative;
        top: 0.7rem;
        g {
          * {
            fill: #fff !important;
          }
        }
      }
      @keyframes spin {
        0% {
          transform: rotateZ(0);
        }
        100% {
          transform: rotateZ(360deg);
        }
      }
      &.remove-icon {
        svg {
          opacity: 0;
          visibility: hidden;
          position: absolute;
        }
      }
    }
  }
}
#productfreightfinalcost {
  transition: all 0.5s ease-in-out;
  border-top: 0.4rem solid $primary;
  display: flex;
  // justify-content: center;
  align-items: center;
  padding-top: 3rem;
  padding-left: 3rem;
  h4 {
    font-size: 18px;
    font-weight: 500;
  }
  .live {
    margin-left: 2.5rem;
    padding-left: 0;
    position: relative;
    display: flex;
    svg {
      width: 3.2rem;
      display: block;
      height: 3.2rem;
      bottom: 0%;
      position: relative;
      left: 0;
      margin-right: 1.2rem;
    }
    p {
      @extend %halant-medium;
      font-size: $f14;
      color: $primary;
      line-height: 1.6rem;
      letter-spacing: 1.4px;
    }
  }
}
#productfreightfinalcost.remove-content {
  opacity: 0;
}
#productfreightfinalcost.show-content {
  h4.animated {
    animation-fill-mode: both;
    animation-iteration-count: infinite;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  .pulse {
    animation-name: pulse;
    animation-duration: 1.2s;
  }
}
@media screen and (max-width: $screen__m) {
  #productfreightinfo {
    .input-fields {
      flex-direction: column;
      .input-btn {
        justify-content: space-between;
        flex-wrap: wrap;
        .input-wrapper {
          flex: 0 0 48% !important;
          width: calc(1 / 2 * 100% - (1 - 1 / 2) * 1%);
          margin: 1.5rem 0;
          .input {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen__xs) {
  #productfreightinfo {
    .freight-columns {
      flex-direction: column;
      .columns {
        padding: 0;
        border: none;
        p {
          line-height: 2;
        }
      }
    }
    .input-fields {
      .input-btn {
        .input-wrapper {
          .input {
            input.input-field {
              min-width: 100%;
            }
          }
        }
      }
    }
  }
  #productfreightfinalcost {
    flex-direction: column;
    align-items: unset;
    h4 {
      text-align: center;
    }
    .live {
      margin: 0;
      padding-left: 4rem;
      margin-bottom: 2rem;
      p {
        line-height: 1.5;
      }
    }
  }
}

@import "../../styles/branding.scss";
@import "../../styles/style.scss";
.button {
  height: 4.9rem;
  border-radius: 2.5rem;
  letter-spacing: 0.3rem;
  line-height: 3.2rem;
  min-width: 20rem;
  text-transform: uppercase;
  font-size: $f16;
  color: $white;
  border: none;
  @extend %nunito-sans-extra-bold;
  outline: none;
  cursor: pointer;
  padding: 0 2rem;
  position: relative;
  transition: all 0.5s ease-out;
  svg {
    position: absolute;
  }

  &.button-with-arrow {
    svg {
      position: static;
      padding-left: 1.5rem;
    }
  }
  &.btn-with-right-icon.right-arrow {
    svg {
      top: 27%;
      width: 2rem;
      transition: all 0.5s ease-out;
    }
  }
  &.btn-with-left-icon.btn-program {
    svg {
      top: 30%;
    }
  }
  &.plp-button.btn-with-left-icon {
    height: 3.1rem;
    font-size: 1.3rem;
    padding: 0 2rem 0 4.8rem;
    svg {
      width: 1.5rem;
      top: 3%;
    }
  }
  &.list-more-products-btn.btn-with-left-icon {
    color: $primary;
    width: 100%;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8.4rem;
    border-radius: 4.2rem;
    background: #f8f8f8;
    svg {
      position: static;
    }
  }
  &.plp-button.btn-save.btn-with-left-icon.btn-white-disabled {
    min-width: 11rem;
    &:hover {
      &:before {
        width: 1.5rem;
        height: 1.9rem;
        top: 17%;
      }
    }
  }
  &.btn-save.btn-with-left-icon.btn-white-disabled {
    &:hover {
      border: 0.1rem solid $primary;
      color: $primary;
      svg {
        display: none;
      }
      &:before {
        content: "";
        background-image: url("../../assets/excelOrange.svg");
        top: 17%;
        width: 2.1rem;
        height: 2.7rem;
        left: 2.5rem;
        background-repeat: no-repeat;
        display: block;
        background-size: cover;
        position: absolute;
      }
    }
  }
  &.cta {
    position: relative;
    padding-right: 2rem;
    &:after {
      content: "";
      border: 0.1rem solid $white;
      border-width: 0 0.3rem 0.3rem 0;
      display: inline-block;
      padding: 0.3rem;
      transform: rotate(-45deg);
      position: absolute;
      top: 2rem;
      right: 2.3rem;
      color: $white;
    }
  }
  &.btn-with-left-icon {
    padding: 0 2rem 0 5.5rem;
    svg {
      left: 2.5rem;
      top: 20%;
    }
  }
  &.btn-saved {
    border-color: $primary;
    &:hover {
      border-color: $black !important;
      color: $white !important;
      background: $black !important;
      &:before {
        background-image: url("../../assets/excelWhite.svg") !important;
      }
    }
  }
  &.btn-with-right-icon {
    padding: 0 5.5rem 0 2rem;
    svg {
      right: 2.5rem;
      top: 20%;
    }
  }
  &.white {
    background-color: $white;
    color: $primary;
    &:after {
      border-color: $primary;
      color: $primary;
    }
  }
  &.long {
    width: 100%;
  }
}
.btn {
  &.primary {
    background-color: $primary;
    width: 100%;
  }
}
button.btn-white-disabled {
  background-color: $white;
  border: 0.1rem solid $light-gray;
  color: $light-gray;
}
button.btn-orange-disabled {
  background-color: $primary;
  color: $white;
  opacity: 0.5;
}
button.btn-white-orange-border {
  background-color: $white;
  border: 0.1rem solid $primary;
  color: $primary;
  &:hover {
    background-color: $primary;
    border: 0.1rem solid $primary;
    color: $white;
    svg {
      path {
        fill: $white;
      }
    }
  }
}
button.btn-white-orange-border.btn-save {
  &:hover {
    background: $primary;
    color: $white;
    svg {
      display: none;
    }
    &:before {
      content: "";
      background-image: url("../../assets/excelWhite.svg");
      top: 17%;
      width: 2.1rem;
      height: 2.7rem;
      left: 2.5rem;
      background-repeat: no-repeat;
      display: block;
      background-size: cover;
      position: absolute;
    }
  }
}
button.btn-orange {
  background-color: $primary;
  color: $white;
  &:hover {
    background: $black;
  }
}
button.btn-orange.btn-with-right-icon {
  svg {
    transition: all 0.5s ease-out;
  }
  &:hover {
    svg {
      right: 1.5rem;
    }
  }
}
button.btn-gray {
  background-color: $gray-5;
  color: $secondary;
  border-bottom: 0.2rem solid $primary;
}
button.btn-black {
  background-color: $black;
  color: $white;
}
button.btn-white-border-shadow {
  background-color: $white;
  color: $primary;
  box-shadow: 0.2rem 0.3rem 0.3rem $shadow-color;
  svg {
    transition: all 0.5s ease-out;
  }
  &:hover {
    svg {
      right: 1.5rem;
    }
  }
}
button.btn-number {
  margin-right: 2rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: transparent;
  border: none;
  color: $white;
  transition: all 0.5s ease-out;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
button.btn-number-active {
  border: 0.2rem solid $white;
  color: $white;
}

@import "../../../../styles/branding";
@import "../../../../styles/style";

.contact-information {
  .address-greeting {
    span {
      @extend %nunito-sans-bold;
      margin-right: 2rem;
      line-height: 2;
    }
    letter-spacing: 0.15rem;
  }
  a {
    margin-right: 2rem;
    line-height: 3;
  }
}

@import "../../../../styles/branding.scss";
@import "../../../../styles/style.scss";
.home-services {
  .home-services-top {
    @extend %block-width-1350;
    margin-bottom: 3rem;
    .home-services-top-inner {
      display: flex;
      justify-content: space-between;
      > div {
        width: calc(50% - 1.5rem);
        img {
          width: 100%;
          height: 100%;
        }
      }
      .home-services-right {
        background: $secondary;
        padding: 4rem;
        box-sizing: border-box;
        .home-services-right-inner {
          padding: 6rem;
          height: 100%;
          text-align: center;
          box-sizing: border-box;
          border: 0.2rem solid $white;
          .contentheader {
            h3,
            h1 {
              color: $white;
              text-align: left;
            }
            h1 {
              padding-bottom: 4rem;
            }
            h3 {
              &:after {
                right: auto;
              }
            }
          }
          p {
            text-align: left;
            margin-bottom: 5rem;
            color: $white;
          }
          button {
            min-width: 100%;
            svg {
              right: 14rem;
            }
            &:hover {
              svg {
                right: 12rem;
              }
            }
          }
        }
      }
    }
  }
  .home-services-bottom {
    background: $gray-5;
    .usps-cont {
      @extend %block-width-1350;
      background: $white;
      padding: 0 1rem;
      .usps {
        box-sizing: border-box;
        padding: 3rem 0 6rem 0;
        background: $gray-5;
        @extend %block-width-1350;
        text-align: center;
        .top {
          display: flex;
          justify-content: space-between;
          padding: 0 8.5rem;
          box-sizing: border-box;
          margin-bottom: 2.5rem;
        }
      }
    }
  }
}
@media screen and (max-width: 1350px) {
  .home-services {
    .home-services-top {
      .home-services-top-inner {
        .home-services-right {
          padding: 2rem;
          .home-services-right-inner {
            padding: 3rem;
            button {
              svg {
                right: 15rem;
              }
              &:hover {
                svg {
                  right: 13rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .home-services {
    .home-services-top {
      .home-services-top-inner {
        .home-services-right {
          padding: 2rem;
          .home-services-right-inner {
            padding: 3rem;
            button {
              svg {
                right: 8rem;
              }
              &:hover {
                svg {
                  right: 6rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen__m) {
  .home-services {
    .home-services-bottom {
      .usps-cont {
        padding: 0 3rem;
        .usps {
          .top {
            padding: 0 1.5rem;
            .usp {
              svg {
                width: 12.4rem;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 970px) and (min-width: 769px) {
  .home-services {
    .home-services-top {
      .home-services-top-inner {
        .home-services-right {
          .home-services-right-inner {
            button {
              height: 6.1rem;
              svg {
                right: 5.5rem;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__s) {
  .home-services {
    .home-services-top {
      .home-services-top-inner {
        flex-direction: column;
        .home-services-left {
          width: 100%;
          margin-bottom: 3rem;
        }
        .home-services-right {
          width: 100%;
          .home-services-right-inner {
            button {
              min-width: 60%;
              &:hover {
                svg {
                  right: 2.5rem;
                }
              }
            }
          }
        }
      }
    }

    .home-services-bottom {
      .usps-cont {
        .usps {
          .top {
            .usp {
              .title {
                h3 {
                  padding: 2rem 0rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 70rem) {
  .home-services {
    .home-services-bottom {
      .usps-cont {
        .usps {
          .top {
            .usp {
              &:last-child {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .home-services {
    .home-services-right {
      .home-services-right-inner {
        button.btn-white-border-shadow.right-arrow {
          height: 6.4rem;
          line-height: 2.2rem;
          svg {
            right: 2rem;
            top: 33%;
          }
        }
      }
    }
    .home-services-bottom {
      .usps-cont {
        .usps {
          .top {
            .usp {
              padding: 0 1.5rem;
              &:nth-child(2) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__xxs) {
  .home-services {
    .home-services-top {
      .home-services-top-inner {
        .home-services-right {
          .home-services-right-inner {
            button {
              width: 100%;
              height: 8rem;
              line-height: 2.2rem;
              svg {
                right: 4rem;
              }
            }
          }
        }
      }
    }
  }
}

@import "../../../../styles/branding.scss";
@import "../../../../styles/style.scss";

#search-products {
  background: $white;
  box-shadow: 0 0.3rem 0.6rem $shadow-color;
  .search-products-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 100rem;
    .search-products-inner-content {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $secondary;
      padding-bottom: 2rem;
      .product-item-content {
        width: calc(25% - 3rem);
        padding: 0 1rem;
        text-align: center;
        .product-item-part {
          margin-bottom: 0;
          a {
            margin: 0 auto;
          }
          &.product-item-image {
            margin-bottom: 2rem;
          }
        }
        .product-item-image {
          height: auto;
        }
        .product-item-buttons,
        .pricing-info-button {
          display: none;
        }
        .product-item-info {
          .product-company-logo,
          .product-number {
            display: none;
          }
          > div {
            width: 100%;
          }
          .product-name {
            font-size: $f12;
            font-weight: normal;
          }
        }
        .product-item-additional-info {
          display: none;
        }
      }
    }
    > p {
      padding: 3rem 0;
      box-sizing: border-box;
      color: $primary;
      text-align: center;
      font-size: $f14;
      @extend %nunito-sans-bold;
    }
    .search-results-button {
      text-align: center;
      button {
        min-width: auto;
        padding: 0;
        margin: 0 0 3rem 0;
        background: none;
        color: $secondary;
        font-weight: normal;
        border-radius: 0;
        font-size: $f12;
        height: auto;
        border-bottom: 0.2rem solid $primary;
      }
    }
  }
}
@media screen and (max-width: $screen__l) and (min-width: $screen__ml) {
  #search-products {
    .search-products-content {
      > p {
        padding: 1.5rem 0;
      }
    }
  }
}

@media screen and (max-width: $screen__s) {
  #search-products {
    .search-products-content {
      .search-products-inner-content {
        .product-item-content {
          .product-item-part {
            padding: 0;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  #search-products {
    .search-products-content {
      .search-products-inner-content {
        .product-item-content {
          width: calc(50% - 3rem);
          &:nth-child(3) {
            display: none;
          }
          &:nth-child(4) {
            display: none;
          }
        }
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  #search-products {
    .search-products-content {
      .search-products-inner-content {
        overflow-y: hidden;
        .product-item-content {
          width: 100%;
          padding: 0 1rem;
          text-align: center;
        }
      }
      .search-results-button {
        button {
          margin-top: 0;
        }
      }
    }
  }
}

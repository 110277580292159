@import "../../styles/branding.scss";
@import "../../styles/style.scss";
.rangeslider-container {
  .rangetext-content {
    position: relative;
    width: 26.5rem;
    span {
      position: absolute;
    }
    span.left {
      left: 0;
    }
    span.right {
      right: 0;
    }
  }
  .rangeslide-content {
    position: relative;
    height: 6rem;
    top: 2rem;
    width: 26.4rem;
    input.input-field-range {
      width: 26.4rem;
      box-sizing: border-box;
      -webkit-appearance: none;
      height: 1.5rem;
      border-radius: 1.3rem;
      outline: none;
      border: none;
      position: absolute;
    }
    .input-range-min-slide {
      background-image: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(0, $light-gray),
        color-stop(0, $secondary)
      );
    }
    .input-range-max-slide {
      background-image: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(1, $secondary),
        color-stop(1, $light-gray)
      );
    }
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      background: $primary;
      cursor: pointer;
      z-index: 10;
      top: -0.5rem;
      position: relative;
      vertical-align: center;
    }
    input[type="range"]::-webkit-slider-runnable-track {
      width: 26.4rem;
      height: 1.5rem;
      border-radius: 1.3rem;
    }
    input[type="range"]::-moz-range-track {
      width: 26.4rem;
      background: $secondary;
      height: 1.5rem;
      border-radius: 1.3rem;
    }
    input[type="range"]::-moz-range-track {
      z-index: -1;
    }
    input[type="range"]:last-of-type::-moz-range-track {
      -moz-appearance: none;
      background: none transparent;
      border: 0;
    }
    input[type="range" i] {
      -webkit-appearance: none;
    }
    input[type="range"]::-moz-range-thumb {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      background: $primary;
      cursor: pointer;
    }
  }
  .rangefield-content {
    width: 26.5rem;
    display: inline-block;
    text-align: center;
    .range-field-wrapper {
      display: inline-block;
      margin: 0;
      .range-field {
        input {
          width: 5.5rem;
          height: 4.5rem;
          padding: 0;
          border-radius: 0;
          background: $white;
          border: 0.1rem solid $light-gray;
          color: $light-gray;
          text-align: center;
        }
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
    span {
      text-transform: uppercase;
      color: $secondary;
      padding: 0 2rem;
      font-size: $f12;
    }
  }
}

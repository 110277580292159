@import "../../../../../styles/branding";
@import "../../../../../styles/style";
.main-block-wrapper {
  border-spacing: 0;
  border-collapse: collapse;
  .main-block-heading {
    tr {
      border-bottom: 0.1rem solid $primary;
      height: 4rem;
    }
  }
  tbody {
    border-bottom: 0.1rem solid $primary;
    color: $secondary;
  }
}

@import "./../../styles/branding.scss";
@import "./../../styles/style.scss";

.select-wrapper {
  position: relative;
  height: 5rem;
  margin-bottom: 4rem;
  max-width: 66rem;
  font-size: 1.2rem;

  .selector {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border: 0.1rem solid $gray-3;
    border-radius: 2.5rem;
    width: 66rem;
    height: 5rem;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    background-color: $white;
    z-index: 2;

    &.filled,
    &.open {
      border-color: $primary;

      .required {
        color: $primary;
      }

      .icon {
        fill: $primary;
      }
    }

    input {
      text-transform: uppercase;
      &::placeholder {
        color: $gray-3;
      }
    }

    .selections {
      max-height: 25rem;
      overflow-y: scroll;
      .option {
        text-transform: uppercase;
        text-align: left;
        padding: 1rem 2.5rem;

        &:hover {
          color: $white;
          background-color: $primary;
        }
      }
    }

    .icon {
      text-transform: uppercase;
      position: absolute;
      top: 1.3rem;
      left: 2.5rem;
      display: flex;
      height: 2.5rem;
      fill: $gray-3;
      align-items: center;
      svg {
        fill: inherit;
        path {
          transition: all 0.3s ease-in-out;
          fill: inherit;
        }
        width: 2.5rem;
        text-align: left;
      }
    }

    .required {
      position: absolute;
      top: 0.1rem;
      height: 5rem;
      right: 4rem;
      text-transform: uppercase;
      z-index: 100;
      color: $gray-3;
      display: flex;
      align-content: center;
      align-items: center;
    }
    input {
      padding: 0 12rem 0 6.5rem;
      box-sizing: border-box;
      border: none;
      box-shadow: none;
      outline: none;
      height: 5rem;
      width: 66rem;
      transition: all 0.3s ease-in-out;
      &::placeholder {
        text-transform: uppercase;
      }
    }
    &.open {
      height: auto !important;
    }
  }
}

.hidden-select {
  display: none !important;
}

.select-wrapper.select-down-arrow {
  margin-bottom: 0;
  width: 16rem;
  .selector {
    width: 16rem;
    height: 3.1rem;
    input {
      padding: 0 2rem 0 4.8rem;
      height: 3.1rem;
      width: 16rem;
      font-size: 1.4rem;
      cursor: pointer;
    }
    &:after {
      content: "▼";
      position: absolute;
      right: 1rem;
      top: 0.7rem;
      color: $light-gray;
      z-index: 2;
      font-size: $f14;
      text-align: center;
      cursor: pointer;
    }
  }
  .selector.open {
    height: auto;
  }
  .icon {
    top: 0.3rem;
    svg {
      width: 1.5rem;
    }
  }
}

@import "../../../../../styles/branding";
@import "../../../../../styles/style";
.create-project {
  display: flex;
  flex-direction: row;
  align-items: center;
  .input-wrapper {
    .input {
      input {
        width: 43rem;
      }
    }
  }
  .save-btn {
    margin: 0;
    margin-left: 3rem;
  }
}
//MEDIA
@media screen and (max-width: $screen__m) {
  .create-project {
    .save-btn {
      .button {
        min-width: unset;
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .create-project {
    .input-wrapper {
      .input {
        input {
          padding-left: 1.5rem;
          width: 31rem;
        }
      }
    }
  }
}

@media screen and (max-width: $screen__xs) {
  .create-project {
    flex-direction: column;
    align-items: baseline;
    .input-wrapper {
      width: 100%;
      .input {
        width: 100%;
        input {
          width: 100%;
        }
      }
    }
    .save-btn {
      width: 100%;
      margin: 0;
      margin-top: 2rem;
      .button {
        min-width: unset;
        width: 100%;
      }
    }
  }
}

@import "../../../styles/branding.scss";
@import "../../../styles/style.scss";

.pricing-info-button {
  width: 31.5rem;
  display: inline-block;
  span {
    font-size: $f14;
    @extend %nunito-sans-regular;
    color: $secondary;
  }
  span.pricing-info-price {
    @extend %nunito-sans-bold;
  }
  .pricing-info-top {
    display: flex;
    &:empty {
      height: 3rem;
      width: 100%;
      display: inline-block;
      + .pricing-info {
        border-radius: none;
      }
      + .pricing-info-bottom {
        border-radius: 23px;
      }
    }
    div {
      text-align: center;
      padding: 1rem;
      box-sizing: border-box;
      width: 33.3%;
      span.pricing-info-qty {
        margin-right: 1rem;
        color: $secondary;
        font-size: $f14;
      }
    }
    .pricing-info {
      &:first-of-type {
        border-radius: 23px 0 0 23px;
        border: 1px solid $light-gray;
      }
      &:nth-of-type(2) {
        border-top: 1px solid $light-gray;
        border-bottom: 1px solid $light-gray;
      }
      &:last-of-type {
        border-radius: 0 23px 23px 0;
        border: 1px solid $light-gray;
      }
    }
  }
  .pricing-info-bottom {
    display: none;
  }
}
.pricing-info-button.pricing-info-color {
  width: 43rem;
  .pricing-info-top {
    div {
      padding: 1rem 1.5rem;
    }
  }
  span {
    font-size: $f18;
    @extend %nunito-sans-regular;
  }
  span.pricing-info-price {
    color: $primary;
    font-size: $f14;
  }
  .pricing-info-top {
    .pricing-info {
      &:first-of-type {
        background: rgba(35, 30, 32, 0.05);
        border-radius: 23px 0 0 23px;
        border: none;
      }
      &:nth-of-type(2) {
        background: rgba(35, 30, 32, 0.15);
        border: none;
      }
      &:last-of-type {
        background: rgba(35, 30, 32, 0.25);
        border-radius: 0 23px 23px 0;
        border: none;
      }
      &:first-of-type:last-of-type {
        border-radius: 23px 23px 0px 0;
        width: 100%;
      }
    }
  }
}
.pricing-info-button.show-product-msrp {
  .pricing-info-top {
    .pricing-info {
      &:first-of-type {
        border-radius: 23px 0 0 0;
      }
      &:last-of-type {
        border-radius: 0 23px 0 0;
      }
      &:first-of-type:last-of-type {
        border-radius: 23px 23px 0px 0;
        width: 100%;
      }
    }
  }
  .pricing-info-bottom {
    background: rgba(35, 30, 32, 0.03);
    border-radius: 0 0 2.3rem 2.3rem;
    display: flex;
    div {
      padding: 1rem 2.5rem;
      box-sizing: border-box;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      .pricing-info-text {
        color: $primary;
        text-transform: uppercase;
        font-size: $f14;
        margin-right: 1.5rem;
      }
      span {
        @extend %nunito-sans-bold;
      }
      &:last-of-type {
        span {
          color: $secondary;
          @extend %nunito-sans-regular;
        }
      }
    }
  }
}

@media screen and (max-width: $screen__xs) {
  .pricing-info-button.show-product-msrp {
    .pricing-info-top {
      .pricing-info {
        .pricing-info-qty {
          margin-right: 1rem;
        }
      }
    }
    .pricing-info-bottom {
      div {
        width: 100%;
      }
    }
  }
}

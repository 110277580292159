@import "../../../styles/branding.scss";
@import "../../../styles/style.scss";

.brands-container {
  .page-main-content {
    padding-top: 0;
    h1 {
      padding-bottom: 3.5rem;
    }
  }
  .brand-tabs-container {
    margin-bottom: 3.5rem;
    display: flex;
    justify-content: center;
    margin-bottom: 3.5rem;
    display: flex;
    justify-content: center;
    display: flex;
    max-width: 50rem;
    justify-content: center;
    align-items: center;
    margin: 0 auto 3.5rem auto;
    button {
      flex: 0 0 50%;
      border-radius: 0;
      &:first-child {
        border-radius: 2.5rem 0 0 0;
      }
      &:last-child {
        border-radius: 0 2.5rem 0 0;
      }
    }
  }
}
@media screen and (max-width: $screen__xs) {
  .brands-container {
    .brand-tabs-container {
      button {
        min-width: 15rem;
        height: 6.4rem;
        font-size: 1.4rem;
        line-height: 1.9rem;
      }
    }
  }
}

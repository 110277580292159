@import "../../../../../../styles/branding.scss";

.open-dropdown {
  .filter-wrapper {
    &.filter-ul {
      &.hidden {
        display: none;
      }
    }
  }
}

@import "../../../styles/branding.scss";
@import "../../../styles/style.scss";
#productfreight {
  .productfreight-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: $page-max-width;
  }
}

@media screen and (min-width: 1025px) {
  #productinforight {
    position: relative;
    transition: all 0.5s ease-in-out;
    .productinforight-inner {
      &.with-freight {
        position: fixed;
        z-index: 1;
        left: 50%;

        transition: all 1s ease-in-out;
        transition: min-height 0.3s ease-in-out;
        transition: margin-left 0s ease-in;

        min-height: 68.7rem;
        box-shadow: 0 0.3rem 0.6rem $white;
        background-color: $white;
      }

      &.without-border {
        transition: all 0.3s ease-in-out;
        transition: margin-left 0s ease-in;
        // width: 64rem;
        padding: 0 2rem;
      }
      &.with-border {
        transition: all 0.3s ease-in-out;
        transition: margin-left 0s ease-in;
        // width: 64rem;
        padding: 0 2rem;
      }
      > div {
        background-color: $white;
        padding: 0;
        box-sizing: border-box;
        overflow: visible;
        // width: 64rem;
      }
    }
  }
}
@media screen and (min-width: 1366px) {
  #productinforight {
    .productinforight-inner {
      &.without-border {
        width: 64rem;
      }
      &.with-border {
        width: 64rem;
      }
      > div {
        width: 64rem;
      }
    }
  }
}

@media screen and (max-width: $screen__m) {
  #productinforight {
    .pricing-info-button.show-product-msrp {
      .pricing-info-top {
        .pricing-info {
          border: none;
          .pricing-info-qty {
            margin-right: 2rem;
          }
          &:first-of-type {
            border-radius: 2.3rem 2.3rem 0 0;
          }
          &:last-of-type {
            border-radius: 0 0 2.3rem 2.3rem;
          }
        }
      }
    }
  }
}

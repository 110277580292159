@import "../../../../styles/branding.scss";
@import "../../../../styles/style.scss";
.product-item-content {
  cursor: pointer;
  .product-item-part {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .product-item-image {
    .product-image {
      img {
        width: 100%;
        max-height: 31rem;
      }
    }
  }

  .product-item-buttons {
    position: relative;
    .react-select__control {
      height: 3.1rem;
      width: 15.8rem;
      min-height: auto;
      border: 0.1rem solid $light-gray;
      &.react-select__control--menu-is-open {
        border-color: $primary;
      }
      &:hover {
        border-color: $primary;
      }
      input {
        padding: 0 2rem 0 4.8rem;
        height: 3.1rem;
        width: 16rem;
        font-size: 1.4rem;
        cursor: pointer;
      }
      .react-select__value-container {
        padding-left: 3.3rem;
        height: 3.1rem;
        .react-select__placeholder {
          font-size: 1.4rem;
          transform: none;
          -webkit-transform: none;
          top: auto;
        }
        .react-select__input {
          height: 2rem;
        }
      }
    }
    .react-select__menu {
      max-height: 25rem;
    }
    .select-down-arrow {
      float: right;
      .selector {
        box-sizing: border-box;
        input {
          &::placeholder {
            @extend %nunito-sans-bold;
          }
        }
      }
      .selector.open {
        input {
          &::placeholder {
            color: $primary;
          }
        }
        &:after {
          transform: rotate(180deg);
          color: $secondary;
        }
      }
      .selections {
        overflow-y: auto;
      }
    }
  }
  .product-item-info {
    display: flex;
    height: 8rem;
    > div {
      width: 65%;
    }
    .product-company-logo {
      width: 35%;
      display: flex;
      align-items: center;
      .company-logo {
        img {
          width: 100%;
          height: 100%;
          padding-right: 1rem;
          box-sizing: border-box;
        }
      }
      .company-logo.brandation-logo {
        margin: 0 auto;
        img {
          width: 5.5rem;
          height: 100%;
        }
      }
    }
    .product-name {
      @extend %nunito-sans-bold;
      text-transform: uppercase;
      line-height: 1.9rem;
      letter-spacing: 0.14rem;
    }
    .product-number {
      font-size: $f12;
    }
  }
  .pricing-info-button {
    width: 100%;
    .pricing-info-top {
      div {
        padding: 0.5rem;
      }
      .pricing-info {
        span {
          font-size: $f12;
        }
      }
    }
  }
  .product-item-additional-info {
    padding: 0 1.5rem;
    box-sizing: border-box;
    display: flex;
    p {
      font-size: $f12;
      width: 50%;
      text-transform: uppercase;
    }
    .product-item-stock {
      color: $primary;
      @extend %nunito-sans-bold;
    }
    .product-item-date {
      text-align: right;
    }
    .product-item-lead-time {
      @extend %nunito-sans-bold;
    }
    .product-item-moq {
      text-align: right;
    }
  }
}
// @media screen and (min-width: $screen__l) {
//   .product-item-content {
//     .product-item-image {
//       height: 35rem;
//     }
//   }
// }
// @media screen and (max-width: 660px) {
//   .plp-container {
//     #listing {
//       .listing-content {
//         .product-item-content {
//           margin-bottom: 3rem;
//           padding-bottom: 3rem;
//           box-sizing: border-box;
//           border-bottom: 0.2rem solid $primary;
//           .product-item-part {
//             display: flex;
//             flex-direction: column;
//             align-items: flex-start;
//             .btn-save.plp-button {
//               margin-bottom: 1rem;
//             }
//             .select-wrapper {
//               height: 3.1rem;
//             }
//             .product-company-logo {
//               display: none;
//             }
//             .pricing-info-button {
//               .pricing-info-top {
//                 flex-direction: column;
//                 > div {
//                   text-align: left;
//                   padding: 0.5rem 0;
//                 }
//                 .pricing-info-first {
//                   border: none;
//                 }
//                 .pricing-info-middle {
//                   border: none;
//                 }
//                 .pricing-info-last {
//                   border: none;
//                 }
//               }
//             }
//           }
//           .product-item-info {
//             height: unset;
//             > div {
//               width: 100%;
//             }
//           }
//           .product-item-additional-info {
//             padding: 0;
//             p {
//               line-height: normal;
//               width: 100%;
//             }
//             .product-item-date,
//             .product-item-moq {
//               text-align: left;
//             }
//           }
//         }
//       }
//     }
//   }
// }

@import "../styles/branding";
@import "../styles/style";

#content-container {
  .customer {
    @extend %block-width-1350;
    padding-top: 5.7rem;
    &.customer-container {
      .customer-account-section {
        margin-bottom: 6rem;
        margin-top: 3rem;
      }
      address,
      .address-greeting {
        color: $secondary;
        padding-left: 2rem;
      }
      #customer-pages {
        a {
          padding-left: 2rem;
        }
      }
      .account-greeting {
        color: $secondary;
      }
      .account-labels {
        font-size: $f14;
        @extend %halant-semi-bold;
        border-bottom: 0.1rem solid $primary;
        color: $primary;
        letter-spacing: 0.14rem;
        text-transform: uppercase;
        padding-bottom: 2.4rem;
      }
      .address-label {
        @extend %nunito-sans-extra-bold;
        font-size: $f14;
        letter-spacing: 0.24rem;
        margin-bottom: 2rem;
      }
      .account-inner {
        margin-top: 3rem;
      }
    }
  }
}

@media screen and (min-width: 769px) {
  #content-container {
    .customer {
      &.customer-container {
        display: flex;
        .account-inner {
          padding-left: 10rem;
          margin-top: 3rem;
        }
        .account-greeting {
          padding-left: 10rem;
        }
      }
    }
  }
}
